
import image1 from '../../src/assets/images/about-us/vinita-patodia.webp';
import image2 from '../../src/assets/images/about-us/kamal-poddar.webp';
import image3 from '../../src/assets/images/about-us/suyash-patodia.webp';
import image4 from '../../src/assets/images/about-us/arun-poddar.webp';
import image5 from '../../src/assets/images/about-us/ajay-kejriwal.webp';
import image6 from '../../src/assets/images/about-us/subodh-kumar-agarwal.webp';
import image7 from '../../src/assets/images/about-us/akthakur.webp';
import image8 from '../../src/assets/images/about-us/sudha-bhushan.webp';
import image9 from '../../src/assets/images/about-us/sandeep-singh.webp';
import image10 from '../../src/assets/images/about-us/kanhaiyalal-beriwal.webp';

const imageData =
[
    {
        "image":`${image1}`,
        "title":"Mrs. Vinita Patodia",
        "id": 1,
        "designation":" Non-Executive Chairperson",
        "description":"Mrs. Vinita Sunil Patodia (DIN No.06360364) an Art graduate is actively involved in social service toward various organizations. Promoter of The Byke Group of Hotels, Director of section 8 company named “Sunil Patodia Welfare Foundation”. Founder member of Girls Hostel Committee of RVG Education Foundation (previously known as Rajasthan Vidyarthi Griha). Mrs. Patodia strongly supports the cause of women empowerment and has been instrumental in taking care of affairs at “Vijay Srigopal Khetan RVG Girls Hostel” for CA Students. Mrs. Patodia is founder trustee of “Shri Doongermal Patodia Charitable Trust” which is regularly contributing for the needs of the society through medical and education assistance. Mrs . Patodia is also the founder member of “MITRA club”. Mrs.Patodia is active participant in Chandivali Branch of Bharat Vikas Parishad. Mrs. Patodia joined Lionism in 2000 along with her spouse Sunil. She is Trustee & Charter Member of Lions Club of Mumbai Heritage Galaxy. She has successfully served as the following positions in her lionistic career. DC – Diwali Milan & New Year Celebrations [2020 – 2022], DC: Lion Lady Auxiliary [2015 – 19], Zone Chairperson: [2014 – 15], DC: Holi Milan [2013 – 14], Club President: [2012 – 13], Club",
        
        
    },
    {
        "image":`${image2}`,
        "title":"Mr. Kamal Poddar",
        "id": 2,
        "designation":"Managing Director",
        "description":"A CA by profession and a fellow member of the Institute of Chartered Accountants of India (ICAI), CA Kamal Poddar is the Managing Director of Choice International Limited. He is a visionary who is currently working on the vision of creating Country’s first nation-level financial services hub. His vision has resulted into the rapid and multi-fold growth of Choice Group. He has been instrumental in converting a CA practice firm into full-fledged",
        
        
    },
    {
        "image":`${image3}`,
        "title":"Mr. Suyash Patodia",
        "id": 3,
        "designation":"Joint Managing Director",
        "description":"Mr. Suyash Sunil Patodia (DIN No: 09489670) is an Associate Member of the Institute of Chartered Accountants of India. He has completed his Three Years Articleship from M/s Delloite Haskins & Sells LLP which is among Top Four Chartered Accountant Firms in India. He is currently engaged in arranging tie-ups with Insurance Companies and is working on on-boarding new clients and intermediaries in one of our subsidiary M/s. Choice Insurance Braking India Private Limited. He has played an active role in developing ISMOS (Online Platform of Choice Insurance Braking) and scaling up Choice Connect (Back-end system) to bring Insurance Division in line with organisation goal of being a fintech. He is also keenly involved in various employee engagement initiatives to bring in a young culture in the Company, he is also being appointed as the Executive Director of our subsidiary Company M/s. Choice",
    
        
    },
    {
        "image":`${image4}`,
        "title":"Mr. Arun Poddar",
        "id": 4,
        "designation":"Executive Director & CEO",
        "description":"Mr. Arun Kumar Poddar  (DIN No : 02819581) is a Fellow Member of the Institute of Chartered Accountants of India and also acts as a partner at S K Patodia & Associates (Chartered Accountants). He holds expertise in Financial Services, Government Advisory and Management Consulting. Holder of strong ability to solve complex company problems using excellent judgment and decision-making skills, he is known in the industry for fostered change in company culture to be more open, transparent, and accountable. Mr. Poddar has worked closely with government, large corporates and retail customers throughout his journey. His vision of making financial services accessible to every citizen of India will drive the organization to be more customer centric and accessible in Tier 3-4 cities.",
    
        
    },
    {
        "image":`${image5}`,
        "title":"Mr. Ajay Kejriwal",
        "id": 5,
        "designation":"Executive Director",
        "description":"CA Ajay Kejriwal is a fellow member of the Institute of Chartered Accountants of India. He also holds a degree of D.I.S.A. issued by I.C.A.I. He has experience of more than 15 years in financial markets. His experience in the field on IFRS convergence, international tax, foreign investment consultancy, and exchange control regulations are added advantage for the broking business. He possesses extensive experience in understanding the financials of companies and funds management within the division.",
        
        
    },
    {
        "image":`${image6}`,
        "title":"Mr. Subodh Kumar Agarwal",
        "id": 6,
        "designation":"Independent Director",
        "description":"Mr Subodh Kumar Agrawal a Chartered Accountant by Profession, also is a Law Graduate from the University of Calcutta. Mr Agrawal also holds certification on “DISA” from the Institute of Chartered Accountants of India. Further to add to his wings Mr. Agrawal also holds a Chartered Accountant Degree from the Institute of Chartered Accountants of Australia. Mr Agrawal has served as the President of the Institute of Chartered Accountants of India and also aided the Exchanges BSE Limited & National Stock Exchange of India Limited( NSE) as Arbitrator in Capital Market cases.",
        
        
    },
    {
        "image":`${image7}`,
        "title":"Mr. A. K. Thakur",
        "id": 7,
        "designation":"Independent Director",
        "description":"Mr. AK Thakur was the General Manager of Union Bank of India and has experienced more than 40 years of services in several and varied areas like Banking services, HR functions, Marketing, and management services.",
    
        
    },
    {
        "image":`${image8}`,
        "title":"Mrs. Sudha Bhushan",
        "id": 8,
        "designation":"Independent Director",
        "description":"Ms. Sudha Bhushan (DIN No. 01749008) is a Chartered Accountant, Company Secretary, Insolvency Resolution Professional, Registered Valuer by Profession & also is an advisor to Bank of Baroda for its NRI and International operations. Ms. Bhushan is an expert in India FDI Policy, FEMA and have been working in these area for more than 15 years. Ms. Bhushan has also authored various books on FDI, Foreign Exchange Management Act, 1999 and International Taxation. A scholar throughout her life Ms. Bhushan has been awarded many awards and recognitions including “Women Empowerment through CA Profession” by Northern India Regional Council (NIRC) of CA Institute.",
    
        
    },
    {
        "image":`${image9}`,
        "title":"Mr. Sandeep Singh",
        "id": 9,
        "designation":"Independent Director",
        "description":"Mr. Sandeep Singh ( DIN No. 02814440) is a Post Graduate in Rural Development from Xavier Institute of Social Sciences, Ranchi. Mr. Singh has a specialization in Media Planning (short term) from Mudra Institute of Communications, Ahmedabad & in General Business Management (Executive Education) from IIM, Bangalore. Mr. Singh also has authored various books on Management Practices & Business Administrations. Mr. Singh is currently the Interim Chairman of the Board of Governors of IIM Kashipur & also on the Advisory Board of National Institute of Mass Communication & Journalism, Ahmedabad and Indian Institute of Democratic Leadership, Mumbai, further he also serves as the “Independent Director” on the Board of Various Companies.",
    
        
    },
    {
        "image":`${image10}`,
        "title":"Mr. Kanhaiya Lal Berwal",
        "id": 10,
        "designation":"Independent Director",
        "description":"Mr. Kanhaiya Lal Berwal is a retired I.P.S and an Ex. Member of the Rajasthan Public Service Commission (R.P.S.C). Presently he is the D.G. Dr. B.R. Ambedkar foundation. He is associated with many Ngo’s for the upliftment of SC/ST and other backward communities especially in the tribal area of Rajasthan."
    
        
    }
]

export default imageData
