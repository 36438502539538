const meta_tags = {

  "services": { 'title': "Get Financial Services in India - Choice", 'lang': "en", 'content': 'Choice is the leading financial service provider in India that offers the best financial services such as stock market investing, wealth management, loans, insurance & tax advisory services etc.', "link": "https://choiceindia.com/services", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "https://choiceindia.com/": {
    'title': 'Choice - Stock Broker & Full Service Stock Brokerage Firm in India', 'lang': "en", 'content': `Choice (formerly Choice Broking) is one of the best stock brokers in India. Start online trading in stocks, commodities, currencies, derivatives with India's leading full-service brokerage firm.`, 'link': "https://choiceindia.com", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "", "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Choice India",
      "alternateName": "Choice",
      "url": "https://choiceindia.com/",
      "logo": "https://choiceindia.com/static/media/choice-logo.1d914765c11f128e8039bf4e174af2ee.svg",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91-022-6707 9999",
        "contactType": "customer service",
        "areaServed": "IN"
      },
      "sameAs": [
        "https://www.facebook.com/ChoiceHQ/",
        "https://twitter.com/ChoiceHQ_Social",
        "https://www.instagram.com/choicehq_social/",
        "https://www.youtube.com/c/ChoiceHQ",
        "https://www.linkedin.com/company/choice-broking/",
        "https://choiceindia.com/"
      ]
    }
    </script>
    `},

  "career": {
    'title': `Career At Choice | Work With India's Leading FinTech Company`, 'lang': "en", 'content': ` Work with Choice India's leading Fintech company. Visit us to know the current job openings.`, "link": "https://choiceindia.com/career", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": ""
  },

  "news-announcement": { 'title': "News & Annoucement - Choice International Limited", 'lang': "en", 'content': "Stay informed with our latest news and announcements. Get updates on company developments and industry news with Choice India", 'link': "https://choiceindia.com/news-announcement", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },


  "insurance": { 'title': "Best Insurance Broker in India - Get a Best Insurance Policy Online", 'lang': "en", 'content': "Get best insurance quotes online from the leading insurance broker in India. Choice helps you with choosing right insurance policy comparing with various insurance companies such as life insurance, car insurance, health insurance & travel insurance etc.", 'link': "https://choiceindia.com/insurance", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "equity-broking": { 'title': "Equity Broking Services - Invest in Stocks, Dervivatives, Commodities and Currencies", 'lang': "en", 'content': "Experience seamless equity trading services with Choice India. Explore our platform and start investing in the stock market today.", 'link': "https://choiceindia.com/equity-broking", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "investor-awareness": { 'title': "Investor Awareness - Choice International Limited", 'lang': "en", 'content': "Explore investor awareness resources and stay updated with the market trends with Choice India. Explore the documents to empower your investing knowledge", 'link': "https://choiceindia.com/investor-awareness", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "investor-charter": { 'title': "Investor Charter - Choice India", 'lang': "en", 'content': "Choice India aims to conduct securities trading with integrity & adherence to regulations, promoting fairness & transparency to enhance investor wealth creation", 'link': "https://choiceindia.com/investor-charter", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "investor-complaints": { 'title': "Investors Complaints - Choice India", 'lang': "en", 'content': "Complaints received from investors and the respective monthly and yearly disposal data.", 'link': "https://choiceindia.com/investor-complaints", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "terms-conditions": { 'title': "Terms and Conditions - Choice International Limited", 'lang': "en", 'content': "Read & Understand the terms and conditions that sets out the terms of a legally binding agreement between you and Choice India.", 'link': "https://choiceindia.com/terms-conditions", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "privacy-policy": { 'title': "Privacy Policy - Choice International Limited", 'lang': "en", 'content': "Learn how we handle your personal information responsibly with our comprehensive privacy policy. Protecting your privacy is our priority", 'link': "https://choiceindia.com/privacy-policy", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "disclaimer": { 'title': "Disclaimer - Choice International Limited", 'lang': "en", 'content': "Read the disclaimer to understand the limitations and liabilities associated with using Choice India's services. Stay informed with all the details.", 'link': "https://choiceindia.com/disclaimer", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "cebpl-policies": { 'title': "CEBPL Policies - Choice International Limited", 'lang': "en", 'content': "", 'link': "https://choiceindia.com/cebpl-policies", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "file-download": { 'title': "Download Demat Account Opening Form", 'lang': "en", 'content': "Access important documents and resources with ease. Download files related to investments and more from Choice India.", 'link': "https://choiceindia.com/file-download", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },
  
  "emi-calculator":{'lang':"en","content":"Plan your finances easy and acurately with our EMI calculator. Calculate your emi amount with respect to the loan amount & make informed decisions with Choice"},


  "contact-us": { 'title': "Get In Touch with Choice’s Customer Support Team", 'lang': "en", 'content': "Get in touch with our Choice customer care team @ +91 88 2424 2424 or drop us a mail at care@choiceindia.com. Contact us or visit any of our nearest branch.", "link": "https://choiceindia.com/contact-us", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },
  "blog/":{'title':"Choice Blog - Stay Updated with Stock Market Information",'lang': "en",'content':"Stay updated with up-to-date thoughts, stories, and ideas about finance only at Choice.",'link':'https://choiceindia.com/blog/'},
  "blog": { 'title': "Choice Blog - Stay Updated with Stock Market Information", 'lang': "en", 'content': "Stay updated with up-to-date thoughts, stories, and ideas about finance only at Choice.", 'link': 'https://choiceindia.com/blog/', 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "faq": { 'title': "Frequently Asked Questions | Choice", 'lang': "en", 'content': "Find FAQs related to our products, services, investment advisory, account opening, and more. Get answers to all your queries about the stock market at Choice.", "link": "https://choiceindia.com/faq", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "sub-broker-franchise": { 'title': "Sub Broker Franchise with Low Deposit - Become a Choice Sub Broker", 'lang': "en", 'content': "Start a sub broker franchise with minimum deposit. Become a stock market sub broker & start your own stock broking franchise business in India with Choice.", 'link': "https://choiceindia.com/sub-broker-franchise", 'link1': "https://choiceindia.com/sub-broker-franchise", 'link2': "https://choiceindia.com/hindi/sub-broker-franchise", 'link3': "https://choiceindia.com/gujarati/sub-broker-franchise", "link4": "https://choiceindia.com/telugu/sub-broker-franchise", "link5": "https://choiceindia.com/marathi/sub-broker-franchise", "link6": "https://choiceindia.com/sub-broker-franchise", "href1": "en-in", "href2": "hi-in", "href3": "gu-in", "href4": "te-in", "href5": "mr-in", "href6": "x-default" },

  "refer-and-earn": { 'title': "Refer and Earn Demat Account - Get ₹500* brokerage reversal per referral.", 'lang': "en", 'content': "Earn high referral rewards with the Best Refer and Earn Demat Account - Refer your friends and family now and get ₹500* brokerage reversal per referral.", 'link': "https://choiceindia.com/refer-and-earn", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "sumeet-bagadia": { 'title': "Sumeet Bagadia - Get Trading Tips, Daily Investment Calls & More", 'lang': "en", 'content': "Sumeet Bagadia is an Executive Director & Head of Technical Research at Choice Broking. Be connected for trading tips, daily intraday & investment calls, weekly market updates & more.", 'link': 'https://choiceindia.com/sumeet-bagadia', 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "ipo-investment-account": { 'title': "IPO Investment: Invest In IPO Online With Choice", 'lang': "en", "content": "IPO Investment - Now investing in IPO is smooth and simple with Choice. Open a Demat account and avail benefits such as listing gains, & short-term profit.", 'link': 'https://choiceindia.com/ipo-investment-account', 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "mutual-funds-investment": { 'title': "Start mutual fund investment now! Invest in Mutual Funds with Choice", 'lang': "en", 'content': "Mutual fund investment got easier with Choice. Invest in mutual funds online, check the plans and know how to invest with best platform for mutual funds.", 'link': "https://choiceindia.com/mutual-funds-investment", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },


  "investment-app": { 'title': "Best Investment App for Stocks, Mutual Funds, ETF and IPO", 'lang': "en", 'content': "Start your investment journey with the top investment app in India. The Choice app allows you to invest in several types of securities, including bonds, ETFs, mutual funds, and derivatives.", 'link': "https://choiceindia.com/investment-app", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },


  "research-listing": { 'title': "Top Stock Research Reports - Choice India", 'lang': "en", 'content': "Refer stock research reports by choice to get technically and fundamentally strong stocks and also Fundamental research that covers IPO, NFO & EIC analysis.", 'link': "https://choiceindia.com/research-listing", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },
  "fact-sheet": { 'title': "Fact Sheet - Choice International Limited", 'lang': "en", 'content': "Visit to Know the CIN number of Choice International Limited.", 'link': "https://choiceindia.com/fact-sheet", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "advisory-for-investors": { 'title': "Advisory for Investors - Choice", 'lang': "en", 'content': "Visit Choice International Limited's official website to know the advisory for the investors.", 'link': "https://choiceindia.com/advisory-for-investors", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "marathi/open-demat-account": {
    'title': "मोफत डीमॅट खाते उघडा + पहिल्या वर्षासाठी AMC शुल्क नाही", 'content': "नि:शुल्क ऑनलाइन डीमॅट खाते उघड़ा केवळ 5 मिनिटात. मिळवा कमीत-कमी ब्रोकरेज आणि DP शुल्क, पहिल्या वर्षात 0 AMC, मोफत शेअर्स रिसर्च आणि 2 पैसे/ट्रेड", 'link': "https://choiceindia.com/marathi/open-demat-account", "lang": 'mr', 'link1': "https://choiceindia.com/open-free-demat-account", 'link2': "https://choiceindia.com/hindi/open-demat-trading-account","link3": "https://choiceindia.com/marathi/open-demat-account","link4": "https://choiceindia.com/open-free-demat-account","link5": "https://choiceindia.com/open-free-demat-account" , "link6": "https://choiceindia.com/open-free-demat-account","href1": "en-in", "href2": "hi-in","href3": "mr-in","href4": "x-default",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "डिमॅट खाते सक्रिय होण्यासाठी किती वेळ लागेल?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "तुम्ही कागदपत्रे सादर केल्यानंतर चार तासांच्या आत तुमचे डिमॅट खाते सक्रिय होते. तुम्ही सादर केलेल्या कागदपत्रांमध्ये काही तफावत किंवा त्रूटी आढळल्यास आमचे चॉईस ब्रोकिंगचे प्रतिनिधी तुमच्याशी संपर्क साधून त्याची पूर्तता करेल. तसेच, तुमचे खाते सक्रिय झाल्यानंतर, तुम्हाला चॉईस ब्रोकिंगकडून एक ईमेल येईल, त्यामध्ये तुम्हाला खाते सक्रिय झाल्याबद्दलची माहिती दिली जाईल."
        }
      },{
        "@type": "Question",
        "name": "डिमॅट खाते उघडण्यासाठी कोणती कागदपत्रे आवश्यक आहेत?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "चॉईस ब्रोकिंगसह तुमचे डिमॅट खाते उघडण्यासाठी खालील कागदपत्रांची आवश्यकता असेल;
    पॅन कार्ड
    कॅन्सल चेक (MIRC कोडसाठी)
    पासपोर्ट साईज फोटो
    आधार कार्ड
    उत्पन्नाचा दाखला"
        }
      },{
        "@type": "Question",
        "name": "पॉवर ऑफ अँटर्नी म्हणजे काय आणि त्याची गरज का आहे?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "पॉवर ऑफ अँटर्नी हा एक असा दस्ताऐवज आहे, ज्याद्वारे तुम्हाला तुमच्या शेअर्सची विक्री झाल्यावर डिमॅट खात्यातून डेबिट करण्याचे अधिकार दिले जातात. त्यामुळे जोपर्यंत तुम्ही तुमची पॉवर ऑफ अँटर्नी सादर करत नाही, तोपर्यंत तुम्हाला तुमची होल्डिंग विक्री करण्यासाठी eDIS सुविधेचा लाभ घेता येतो. यासाठी कोणतेही होल्डिंग्सची विक्री करण्यापूर्वी तुम्ही तुमचे होल्डिंग अधिकृत करणे गरजेचे असते. म्हणजेच एकप्रकारे तुमच्या POA ची स्वाक्षरी केलेली प्रत ही तुम्हाला तुमचे होल्डिंग कोणत्याही अडचणींशिवाय विकण्यास सक्षम करते.
    प्POA साठी त्याची एक प्रत तुम्हाला तुमच्या ईमेल आयडीवर पाठवली जाईल. त्यावर तुम्ही तुमची स्वाक्षरी करुन आम्हाला कुरिअर करणे गरजेचे आहे. तुम्ही स्वाक्षरी केलेली POA ची प्रत आमच्या संकेतस्थळावर -https://choiceindia.com/ नमूद केलेल्या मुख्य कार्यालयाच्या पत्त्यावर पाठवावी."
        }
      },{
        "@type": "Question",
        "name": "मी पॉवर ऑफ अँटर्नी कोठे कुरिअर करावी?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "तुम्ही स्वाक्षरी केलेली POA ची प्रत आमच्या मुख्यालयाच्या खाली दिलेल्या पत्त्यावर कुरिअर करावी
    चॉईस इंटरनॅशनल लिमिटेड,
    सुनील पतोडिया टॉवर,
    जे.बी. नगर, अंधेरी (पूर्व) मुंबई ४०००९९"
        }
      },{
        "@type": "Question",
        "name": "चॉईस ब्रोकिंगसह डिमॅट खाते उघडण्यासाठी काही शुल्क आकारले जाते का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "चॉईस ब्रोकिंगसह डिमॅट खाते उघडण्यासाठी काही शुल्क आकारले जाते का?"
        }
      },{
        "@type": "Question",
        "name": "डिमॅट खाते न उघडता मी ऑनलाईन ट्रेडिंग खाते कसे ओपन करु शकतो?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "एनएसई/ बीएसई सेक्यूरिटीजसाठी तुमच्या ट्रेडिंग खात्यासह डिमॅट खाते असणे आवश्यक असते. चलन (Currency) आणि कमोडिटीसाठी डिमॅट खात्याची आवश्यकता नसते. त्यामुळे जर तुमच्या ट्रेडिंगच्या गरजा मर्यादित असतील, तर तुम्हाला ट्रेडिंग खाते निवडणे सोईचे राहिल. तसेच ब्रोकरसोबतच्या कागदपत्रांमध्ये त्याचा स्पष्ट उल्लेख करणे अनिवार्य असते. यासंबंधी आधिक माहितीसाठी care@choiceindia.com ला एकदा आवश्य भेट द्या. किंवा आमच्या ग्राहक सेवा टीमशी संपर्क साधू शकता. आमचे प्रतिनिधी तुम्हाला योग्य ते मार्गदर्शन करुन तुमच्या सर्व शंकांचे निरसन करतील."
        }
      },{
        "@type": "Question",
        "name": "मी माझे जुने डिमॅट दुसऱ्या ब्रोकरकडून चॉईस ब्रोकिंगमध्ये कसे बदलू शकतो?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "तुम्हाला तुमच्या जुन्या डीपीची CML (क्लायंट मास्टर लिस्ट) देऊन, आमच्याकडे नवीन डिमॅट खाते ओपन करणे आवश्यक आहे."
        }
      },{
        "@type": "Question",
        "name": "मी माझ्या ट्रेडिंग खात्यावर एकपेक्षा जास्त डिमॅट खाती मॅप करु शकतो का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "हो. तुम्ही तुमच्या ट्रेडिंग खात्याशी एकपेक्षा जास्त डिमॅट खाती लिंक करु शकता. तेही वेगवेगळ्या डीपीसह. पण ती सर्व खाती तुमच्या नावावर असणे आवश्यक आहे. पण महत्त्वाची गोष्ट म्हणजे, सर्व लिंक केलेल्या डिमॅट खात्यांमधून तुम्ही पे इन किंवा प्लेज आदींसाठी शेअर देऊ शकता. पण ट्रेडिंग खात्यातील पेआउट केवळ"
        }
      },{
        "@type": "Question",
        "name": "मी दुसऱ्या एखाद्या डिमॅट खात्यातून माझ्या चॉईस ब्रोकिंगच्या डिमॅट खात्यात शेअर्स कसे हस्तांतरित करु शकतो?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "चॉईस इक्विटी ब्रोकिंग प्रायव्हेट लिमिटेडमध्ये तुम्ही घेत असलेल्या डिमॅट खात्याच्या टार्गेट डीपी आयडीचा उल्लेख करावा लागेल. त्यानंतर तुम्हाला तुमच्या आधीच्या डीपीला ऑफ मार्केट डिलिव्हरी इन्स्ट्रक्शन स्पिल (DIS) प्रदान करणे आवश्यक असते."
        }
      },{
        "@type": "Question",
        "name": "शेअर बाजारातील गुंतवणुकीसाठी डिमॅट खाते उघडणे आवश्यक आहे का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "जर तुम्ही इक्विटीमध्ये ट्रेडिंग/ गुंतवणूक करणार असाल, तर डिमॅट खाते अनिवार्य आहे. डिमॅट खात्यांमध्ये तुमचे शेअर इलेक्ट्रॉनिक स्वरुपात सुरक्षित ठेवले जातात. जर तुम्ही फक्त फ्युचर्स आणि ऑप्शन्समध्ये ट्रेडिंग करणा असाल, तर डिमॅटची आवश्यकता नाही."
        }
      },{
        "@type": "Question",
        "name": "मी माझे जुने डिमॅट खाते चॉईससह वापरू शकतो का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "यासाठी दोन पर्याय आहेत. एकतर तुम्ही चॉईस ब्रोकिंगमध्ये नवीन डिमॅट खाते ओपन करा. आणि जुन्या डिमॅटमधून सर्व शेअर हस्तांतरित करा किंवा ते चॉईसच्या खात्यात विलीन करा. यासाठी तुम्ही शेअर हस्तांतरणासाठी मॅन्यूअल किंवा ऑनलाईन पर्यांयाची निवड करु शकता.
    मॅन्यूअल केल्यास;
    १. कोणता डीपी (डिपॉझिटरी पार्टिसिपंट) समभाग घेत आहे. ते तपासा. कारण आपल्याकडे दोनप्रकारचे डिपॉझिटर्स आहेत. पहिले म्हणजे नॅशनल सेक्युरिटीज डिपॉझिटरी लिमिटेड (NSDL) आणि दुसरे म्हणजे सेंट्रल डिपॉझिटरी सर्व्हिसेस लिमिटेड (CDSL)
    २. जर तुमचे नवीन डिमॅट त्याचप्रकारच्या डीपीकडे असेल, तर ते इंटर डिपॉझिटरी असेल. जर ते दोन्हीही वेगवेगळे असतील, तर इंट्रा डिपॉझिटरी हस्तांतरण असेल.
    ३. तुमच्या जुन्या खात्याच्या DP द्वारे प्रदान केलेली डिलिव्हरी इंस्ट्रक्शन स्लिप (DIS) भरा. त्यानंतर मोड ऑफ ट्रान्सफरमध्ये जाऊन हस्तांतरण इंटर डिपॉझिटरी कि इंट्रा डिपॉझिटरी आहे हे तपासून तो पर्याय निवडा.
    ४. समभागांची नावे, प्रमाण आणि त्यांचे ISIN क्रमांक आदी आवश्यक तपशील भरा.
    ५. नवीन खात्याचा १६ डिजीट आयडी भरा.
    ६. जुन्या ब्रोकरकडे स्वाक्षरी केलेली डीआयएस सादर करुन पोचपावती घ्या.
    ७. तीन-पाच दिवसांत शेअर्स नवीन खात्यात हस्तांतरित होतील.
    ऑनलाईन ट्रान्सफर
    हा अतिशय सोपा पर्याय आहे. केवळ CDSL च्या वेबसाईटला भेट द्या, आणि आवश्यक सर्व तपशीलांसह नोंदणी करा. त्यासाठी तुम्हाला दिलेला एक फॉर्म भरावा लागेल. आणि प्रिंट फॉर्म हा पर्याय निवडा. हे CDSL ला हस्तांतरणाची प्रक्रिया सुरु करण्यासाठी सुचित करते. यासाठीची पडताळणी पूर्ण झाल्यानंतर तुमच्या खात्याचा लॉगइन तपशील तुमच्या ईमेल आयडीवर पाठवला जाईल. त्यानंतर CDSL वेबसाईटवरुन तुमच्या खात्यात लॉगइन करा. आणि नवीन खात्यात शेअर हस्तांतरित करण्यास सुरुवात करा."
        }
      },{
        "@type": "Question",
        "name": "मी डिमॅट खात्यात पैसे ठेवू शकतो का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "नक्कीच, तुम्ही तुमच्या डिमॅट/ ट्रेडिंग खात्यात आवश्य पैसे ठेवू शकता. पण आम्ही तुम्हाला ट्रेडिंग/ गुंतवणुकीसाठी आवश्यक तेवढीच रक्कम तुमच्या डिमॅट खात्यात ठेवण्याचा सल्ला देऊ."
        }
      },{
        "@type": "Question",
        "name": "ऑनलाईन डिमॅट खाते उघडणे सुरक्षित आहे का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "ऑनलाईन डिमॅट खाते ओपन करणे पूर्णपणे सुरक्षित आहे. मात्र यासाठी योग्य ब्रोकर निवडणे आवश्यक आहे."
        }
      },{
        "@type": "Question",
        "name": "ट्रेडिंग खात्यातून पैसे जमा करणे आणि काढणे यासाठी मर्यादा आहेत का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "पैसे जमा करणे किंवा काढणे, यासाठी कोणतीही मर्यादा नाही. मात्र जेव्हा एकपेक्षा जास्त व्यवहारांची आवश्यकता असेल, तेव्हा MPS/NEFT/RTGS साठी एका व्यवहारासाठी कमाल रक्कम बँकेद्वारे ठरवली जाते. UPI द्वारे ट्रान्सफरची मर्यादा एक लाख इतकी आहे. अनेक ब्रोकरच्या बाबतीत पैसे काढण्याची मर्यादा नसते. पण त्या ब्रोकरची संपूर्ण माहिती घेऊन ती पडताळून घ्या."
        }
      },{
        "@type": "Question",
        "name": "मी स्टॉक, चलन, डेरिव्हेटिव्ह आणि कमोडिटी ट्रेडिंगसाठी समान डिमॅट किंवा ट्रेडिंग खाते वापरु शकतो का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "नक्कीच, तुम्ही तुमचे खाते कोणत्याही मालमत्ता वर्गाता (assets) ट्रेडिंग/ गुंतवणुकीसाठी वापरु शकता."
        }
      },{
        "@type": "Question",
        "name": "चॉईसद्वारे डिमॅट खाते उघडण्याचे किती शूल्क आकारले जाते?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "ते पूर्णपणे मोफत असून, चॉईसद्वारे डिमॅट खाते ओपन करण्याचे कोणतेही शुल्क आकारले जात नाही."
        }
      },{
        "@type": "Question",
        "name": "ऑनलाईन डिमॅट आणि ट्रेडिंग खाते उघडण्यासाठी पात्रता आणि निकष काय आहेत?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "वास्तविक, वयाचा असा कोणताही निकष नाही. म्हणजे १८ वर्षांखालील व्यक्तीदेखील डिमॅट आणि ट्रेडिंग खाते ओपन करु शकतात. पण अल्पवयीन असल्याने त्याच्या पालकांकडेच त्याचे पालकत्व असते. पण तरीही डिमॅट खाते ओपन करण्यासाठी काही महत्त्वाचे निकष आहेत.
    १. भारतीय नागरीक असावा.
    २. वयोमर्यादा १८
    ३. पॅन कार्ड
    ४. वैध पत्त्याचा पुरावा (आधार कार्ड, मतदान ओळखपत्र, पासपोर्ट, ड्रायव्हिंग लायसन्स, युटिलिटी बिल)
    भारतात डिमॅट खाते ओपन करण्यासाठी तुम्हाला ओळखपत्र, पत्ता आणि उत्पन्न ITR पुराव्यासह फोटो देणे आवश्यक असते."
        }
      },{
        "@type": "Question",
        "name": "डिमॅट खाते सक्रिय होण्यासाठी किती वेळ लागेल?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "कागदपत्रे व्यवस्थित असल्यास, डिमॅट खाते २४ ते ४८ तासांत सक्रिय होते."
        }
      },{
        "@type": "Question",
        "name": "पेपरलेस डिमॅट खाते उघडणे म्हणजे काय?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "डिमॅट खाते उघडण्याच्या पेपरलेस पर्यायामध्ये कोणतीही कागदपत्रांचे भौतिक (physical) सादरीकरण नसते. सर्व कागदपत्रे ऑनलाईन सादर करावी लागतात. तसेच ही प्रक्रिया पूर्ण होण्यासाठी १० मिनिटांचा वेळ लागतो."
        }
      }]
    }
    </script>
    
    ` },

  "open-free-demat-account": {
    'title': "Open Free Demat Account. Online Account Opening - Choice", 'lang': "en", 'content': "Open a demat account with Choice - Online free Demat account opening in few steps. Trade @2 paisa, low DP & brokerage charges and avail 0 AMC*. Invest/trade in equity, fno, mutual funds & more.", 'link': 'https://choiceindia.com/open-free-demat-account', 'link1': "https://choiceindia.com/open-free-demat-account", 'link2': "https://choiceindia.com/hindi/open-demat-trading-account", 'link3':"https://choiceindia.com/marathi/open-demat-account", 'link4':"https://choiceindia.com/open-free-demat-account",'link5':"https://choiceindia.com/marathi/open-demat-account",'link6':"https://choiceindia.com/marathi/open-demat-account", "href1": "en-in", "href2": "hi-in", "href3": "mr-in", "href4": "x-default", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "How long will it take for the Demat Account to be activated?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Your account will be activated within 4 hours of document submission. If there is any gap in the documentation, the Choice support team will reach out to you. Once your account is activated, you will receive an email from Choice informing you about the same."
      }
    },
    {
      "@type": "Question",
      "name": "Is Demat account free?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes. You can open a demat account for free with Choice. You also have free AMC for the 1st year and trade at nominal broking charges."
      }
    },
    {
      "@type": "Question",
      "name": "Can I open a Demat account online?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes. You can open a demat account online very easily. You just have to follow the few easy steps mentioned above."
      }
    },
    {
      "@type": "Question",
      "name": "Are there any charges for Demat Account opening with Choice?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Opening a demat account is absolutely free at Choice. You also have the advantages of a full-service broker at minimum brokerage charges with free 1st year AMC."
      }
    },
    {
      "@type": "Question",
      "name": "How do I open a Trading Account without opening a Demat Account?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "NSE/BSE mandates a Demat with your Trading Account for securities. Currency and Commodities do not require a Demat Account, so if your trading requirements are restricted to that; you can open a trading account. This will have to be specified in the documentation process with the broker. For more information regarding the same, you can contact our customer care team at care@choiceindia.com."
      }
    },
    {
      "@type": "Question",
      "name": "How do I switch my Demat from another broker to Choice?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You will be required to provide us with the CML (Client Master List) Copy of your earlier DP and open a new Demat Account with us."
      }
    },
    {
      "@type": "Question",
      "name": "Can I map multiple Demat Accounts to my Trading Account?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you can link multiple Demat Accounts to your Trading Account, even with different DPs, but they should all be in your name. However, an important thing to note is that from all the linked Demat Accounts you can give shares for Pay In/Pledge, etc; but the payout from the Trading Account will only get credited to the primary Demat Account."
      }
    },
    {
      "@type": "Question",
      "name": "How can I transfer shares from another Demat Account to my Choice Demat Account?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You will be required to provide the Off Market Delivery Instruction Slip (DIS) to your previous DP, mentioning the target DP ID of the Demat Account you are holding in Choice Equity Broking Private Limited."
      }
    },
    {
      "@type": "Question",
      "name": "Is opening a Demat account necessary for stock market investment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A Demat account is mandatory if you are going to trade/invest in equities. Demat accounts hold your shares in electronic form. If you are going to simply trade in futures and options then a Demat is not required."
      }
    },
    {
      "@type": "Question",
      "name": "Can I use my previous Demat Account with Choice?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There are 2 options, either you open a new Demat account with Choice and transfer all shares from the old Demat, or merge the old Demat into the new one. You can opt for manual or online transfer of shares.\nManual Transfer\nAscertain which DP (Depository Participant) is holding the shares, there are 2 depositories in India, National Securities Depository Limited (NSDL) and Central Depository Services Limited (CDSL).\nIf your new Demat is with the same DP then it will be an inter-depository transfer, and if it's different; it will be an intra-depository transfer.\nNext, fill the Delivery Instruction slip (DIS) provided by the DP of your old account. In the 'Mode of Transfer' field, please select whether the transfer is inter-depository or intra-depository. This is crucial.\nFill all required details like names of shares, quantity and their ISIN numbers.\nEnter the 16-character ID of the new account.\nSubmit the signed DIS to the old broker and take an acknowledgment slip.\nShares will be transferred to the new account in 3-5 working days.\nOnline Transfer\nThis option is much simpler, just visit the CDSL website and register with all required details.\nYou will be required to fill a form and use the 'Print Form' option; this notifies CDSL to start the verification process.\nAfter verification is completed, login details to your account will be sent to your email id.\nLogin to your account from the CDSL website and start transferring shares to the new account."
      }
    },
    {
      "@type": "Question",
      "name": "Can I hold money in a Demat Account?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely, you can keep money in the Demat/Trading account. It is, however, advisable to hold only the amount that will be required for trading/investing."
      }
    },
    {
      "@type": "Question",
      "name": "Is Opening a Demat Account Online Safe?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "It's absolutely safe to open a Demat account online but choosing the right broker is essential for this."
      }
    },
    {
      "@type": "Question",
      "name": "Is there a limit for Deposit and Withdrawal Money from a Trading Account?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There is no limit as such for depositing or withdrawing money, however, it will need multiple transactions. For MPS/NEFT/RTGS/ the maximum amount allowed in a single transaction is decided by the bank. UPI transfers have a limit of 1 lakh per transaction. In the case of most brokers, there is no withdrawal limit but do check once with your broker."
      }
    },
    {
      "@type": "Question",
      "name": "Can I use the same Demat Account for Equity trading, Currency trading, Derivatives and Commodity Trading?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you can use the same Demat or Trading Account for Equity Trading, Currency Trading, Derivative Trading, and Commodity Trading."
      }
    },
    {
      "@type": "Question",
      "name": "What are the eligibility criteria for opening an online Demat & Trading Account?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "As far as age is concerned, there is no minimum age criterion i.e., under 18 can also have Demat & Trading accounts opened. The parents or guardians will be in charge of the account until the minor comes of age."
      }
    },
    {
      "@type": "Question",
      "name": "What is Paperless Demat account opening?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In this option for Demat account opening, there is no physical submission of any document involved. All documents are submitted online and take only 5 minutes to complete!"
      }
    }
  ]
}
</script>

    ` },

  "about-us": { 'title': "Best Stock Broking Company in India - Choice Broking", 'lang': "en", "content": "Choice is one of the best stock broking companies in India. Start trading in stocks, derivatives, currency, commodity & investing in mutual funds with India's leading share broking company. Open a free trading account with us.", "link": 'https://choiceindia.com/about-us', "faqscript": ``, 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  // "fables": { 'title': "Choice - Stock Broker & Full Service Stock Brokerage Firm in India", "content": "Choice (formerly Choice Broking) is one of the best stock brokers in India. Start online trading in stocks, commodities, currencies, derivatives with India's leading full-service brokerage firm.", "faqscript": `` },

  // "contact": { 'title': "Choice - Stock Broker & Full Service Stock Brokerage Firm in India", "content": "Choice (formerly Choice Broking) is one of the best stock brokers in India. Start online trading in stocks, commodities, currencies, derivatives with India's leading full-service brokerage firm.", "faqscript": `` },

  "partner": { 'title': "Become a Stock Market Agent with Choice", 'lang': "en", "content": "Become a stock market agent with Choice. Get the highest revenue share, local branch support, and training to start your entrepreneurship journey.", "link": "https://choiceindia.com/partner", "faqscript": ``, 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "investors": { 'title': "Choice International Limted's Quarterly Results, Corporate Annoucement", 'lang': "en", "content": "Stay tuned with us to know more about Choice International Limited's most recent happenings, including its quarterly earnings, corporate announcements, shareholding patterns, and AGM.", "link": "https://choiceindia.com/investors", "faqscript": ``, 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "client-details-update": { 'title': "Update Client Details - Change Your Email and Phone Number", 'lang': "en", 'content': "Update your client details at ease and ensure a smooth and secure service at Choice. We ensure the safety of your information.", 'link': "https://choiceindia.com/client-details-update", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "annual-report": { 'title': "Annual Report - Choice International Limited", 'lang': "en", 'content': "Review our annual performance and achievements. Access our comprehensive annual report for insights into Choice India's operations and yearly performance.", 'link': "https://choiceindia.com/annual-report", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "financial-info": { 'title': "Financial Information - Choice International Limited", 'lang': "en", 'content': "Gain insights into the financial performance of choice international ltd. Access financial statements and analyse the company's financial health.", 'link': "https://choiceindia.com/financial-info", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "news-media": { 'title': "News and Media - Choice", 'lang': "en", 'content': "", 'link': "https://choiceindia.com/news-media", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "board-of-directors": { 'title': "Board Of Directors", 'lang': "en", 'content': "Meet our board of directors and leadership team. Learn about the expertise and vision driving Choice India's success", 'link': "https://choiceindia.com/board-of-directors", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "voting-results": { 'title': "Voting Results - Choice International Limited", 'lang': "en", 'content': "", 'link': "https://choiceindia.com/voting-results", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "notices": { 'title': "Notices - Choice International Limited", 'lang': "en", 'content': "Go through the important official notices and announcements. Access important updates and notifications associated with Choice.", 'link': "https://choiceindia.com/notices" },

  "code-conduct": { 'title': "Choice - Stock Broker & Full Service Stock Brokerage Firm in India", 'lang': "en", "content": "Choice (formerly Choice Broking) is one of the best stock brokers in India. Start online trading in stocks, commodities, currencies, derivatives with India's leading full-service brokerage firm.", "faqscript": ``, 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "commodity-trading": {
    'title': "Open Commodity Trading Account Online for Free at Choice", 'lang': "en", 'content': "Open a free online commodity trading account. Start trading commodities with Choice and avail low brokerage, low dp charges, free research advice and more.", 'link': "https://choiceindia.com/commodity-trading", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "", "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "How much time does it take to complete the registration of a commodity trading account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "It takes about 48 hours for a commodity account in the online process and for the offline process it may take 4 days to 10 days."
        }
      },{
        "@type": "Question",
        "name": "What are the documents required to open a commodity trading account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "To open a commodity trading account, you need to submit the following documents:
    Proof of Identity (PAN Card, Aadhar card, ID card, etc)
    Proof of Address (Aadhar card, utility bills, etc)
    Photographs
    Proof of Income (ITR slip, Salary Slip, Bank Statement)
    Bank details
    Signature"
        }
      },{
        "@type": "Question",
        "name": "Is it necessary to provide power of Attorney (POA) while opening a commodity trading account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes. The Power of Attorney is important for executing trades in a commodity account. Hence, one has to provide the same as per the regulators."
        }
      },{
        "@type": "Question",
        "name": "Are any charges applicable while opening a commodity trading account with Choice?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No. There are no account opening charges with Choice. However, one should take into account the brokerage charges associated."
        }
      },{
        "@type": "Question",
        "name": "Can I use the same commodity trading account for all segments?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes. Once you open an account with Choice, you have the authority to trade with equity, commodity, currency and derivatives. The procedure for all of them is constant."
        }
      },{
        "@type": "Question",
        "name": "How long will it take for the commodity trading account to be activated?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A commodity trading account takes 4 hours to be activated through an online process after providing the documents."
        }
      },{
        "@type": "Question",
        "name": "Are NRIs able to open a commodity trading account in India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes. An NRI can start a trading account with Choice with the same procedure of any citizen residing in India."
        }
      },{
        "@type": "Question",
        "name": "Can I trade in multiple types of commodities through a commodity account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes. With choice, you have the privilege to trade in multiple types of commodities."
        }
      }]
    }
    </script>
    `},

  "derivatives-trading": { "title": "Open Derivatives Trading Account Online for Free with Choice", 'lang': "en", "content": "Open a free online derivatives trading account to trade futures and options contracts at ease. Start derivatives trading today with Choice.", "link": "https://choiceindia.com/derivatives-trading", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "equity-stock-trading": { 'title': "Open Equity Trading Account Online for Free with No AMC Charges", 'lang': "en", 'content': "Open a free stock trading account online at Choice with 0 AMC fees and take advantage of the minimal brokerage and dp fees, free research, and advice services.", 'link': "https://choiceindia.com/equity-stock-trading", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "currency-forex-trading": { 'title': "Open Currency / Forex Trading Account Online for Free at Choice", 'lang': "en", 'content': "Open a free currency/forex trading account online. Start trading currency at minimal brokerage fees and enjoy hasselfree trading.", 'link': "https://choiceindia.com/currency-forex-trading", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "campaign/open-demat-account": { 'title': "Open a Free Demat Account Online - Low Brokerage + No AMC For 1st Year", 'lang': "en", 'content': "Open a Free Demat Account Online at Choice with Low Brokerage Charges, First Year AMC Free, Free Research with 78% Success Ratio & more.", 'link': "https://choiceindia.com/campaign/open-demat-account", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "hindi/sub-broker-franchise": { 'title': "चॉइस सब ब्रोकर बनें और शूरू करे सब ब्रोकर फ्रैंचाइज़ी सबसे कम डिपॉजिट में", 'content': "सब ब्रोकर बनें और शूरू करे सब ब्रोकर फ्रैंचाइज़ी सबसे कम डिपॉजिट में चॉइस के साथ | पाईये उच्चतम ब्रोकरेज शेयरिंग, ₹ १००० तक प्रति खाता इन्सेंटिव्ह और बहोत कुछ| आजही चॉइस सब ब्रोकर बनें|", 'link': "https://choiceindia.com/hindi/sub-broker-franchise", 'lang': "hi", 'link1': "https://choiceindia.com/sub-broker-franchise", 'link2': "https://choiceindia.com/hindi/sub-broker-franchise", 'link3': "https://choiceindia.com/gujarati/sub-broker-franchise", "link4": "https://choiceindia.com/telugu/sub-broker-franchise", "link5": "https://choiceindia.com/marathi/sub-broker-franchise", "link6": "https://choiceindia.com/sub-broker-franchise", "href1": "en-in", "href2": "hi-in", "href3": "gu-in", "href4": "te-in", "href5": "mr-in", "href6": "x-default" },

  "campaign/sub-broker-franchise": { 'title': "Sub Broker Franchise with Low Deposit - Become a Choice Sub Broker", 'lang': "en", 'content': "Become a Sub Broker with Choice. Open a sub brokership business or franchise with minimum deposit* & get upto 75% of total brokerage for whole trading life-span of the client.", 'link': "https://choiceindia.com/sub-broker-franchise", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "corporate-governance": { 'title': "Corporate Goveranc - Choice International Limited", 'lang': "en", 'content': "", 'link': "https://choiceindia.com/corporate-governance", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "share-holding-pattern": { 'title': "Share Holding Pattern - Choice International Limited", 'lang': "en", 'content': "Gain insights into the shareholding structure of Choice. Explore the share holding pattern and the company's holdings at the current date.", 'link': "https://choiceindia.com/share-holding-pattern", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },



  "brokerage-charges": {
    'title': "Brokerage Charges: Lowest Brokerage Charges In India | Choice", 'lang': "en", "content": "Brokerage Charges:  Get the lowest brokerage charges in India for delivery, intraday, derivatives, commodities, and currencies with Choice.", "link": "https://choiceindia.com/brokerage-charges", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What are Intraday Brokerage Charges and how are they calculated?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Intraday Brokerage Charge is the small percentage of fee traders pay to brokers on their Intraday Trade (when traders buy/sell on the same day). Intraday charges are calculated at 0.02% on Choice Broking."
        }
      },{
        "@type": "Question",
        "name": "What are Delivery Brokerage Charges and how are they calculated?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Delivery Brokerage Charges are levied on Delivery Trades (when an investor buys on one day and sells on another day). They are calculated at 0.2% on Choice Broking."
        }
      },{
        "@type": "Question",
        "name": "What are the Brokerage Charges for Equities Trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The brokerage charges for Equities Trading is 0.02% for Delivery and Intraday, 0.02% for Futures and Rs. 25 per lot. for Options."
        }
      },{
        "@type": "Question",
        "name": "What are the Brokerage Charges for Futures and Options Trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The Brokerage Charges for Futures is 0.02% (Equity, Currency, Commodity). For Options, it is Rs. 25 per lot (Equity), Rs. 20 per lot (Currency) and Rs. 50 per lot (Commodity)."
        }
      },{
        "@type": "Question",
        "name": "What are Depository Participant Charges?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Once an investor buys stocks, they are digitally held by the Depository, i.e., NSDL and CDSL in India. The Depository charges the Depository Participant (broker) a fee for holding these securities (only for delivery), which in turn is charged by the DP to the investor."
        }
      }]
    }
    </script>
    ` },

  "open-trading-account": {
    'title': "Open Trading Account in Few Steps! Trade Effortless Earn Limitless", 'lang': "en", "content": "Open a trading account online with Choice. Follow simple steps for free trading account opening & trade at low DP charges, lowest intraday charges with expert research calls for seamless trading.",
    "link": "https://choiceindia.com/open-trading-account", 'link1': "https://choiceindia.com/open-trading-account", 'link2': "https://choiceindia.com/hindi/trading-account", 'link3': "https://choiceindia.com/open-trading-account", "link4": "", "link5": "", "link6": "", "href1": "en-in", "href2": "hi-in", "href3": "x-default", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "How much time is required to open a trading account online?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Online account opening has become fairly convenient not to mention quick. If you have all the required documents handy, and the KYC is done, then the account can be opened in 15 minutes. If the KYC is not done then it could take a couple of working days."
        }
      },{
        "@type": "Question",
        "name": "Are there any charges applied by stock brokers while opening a trading account online?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "It’s hard to say about other brokers, but Online Trading Account opening or Online Demat Account opening with Choice broking is absolutely free. There are no charges whatsoever."
        }
      },{
        "@type": "Question",
        "name": "What are the AMC charges for trading accounts with Choice?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "First year AMC is free, next year onwards Choice Broking takes minimal charges for maintaining and keeping your trading account ready to use whenever you’d like to.
    First Year AMC is Free and then *3 plans to choose from:
    
    Rs.200/- + GST Yearly Plan
    Rs.1500/- + GST - Lifetime AMC Covered
    Rs.3000/- Full Refundable AMC (Full amount will be refunded when you close the account with Choice Broking)"
        }
      },{
        "@type": "Question",
        "name": "What documents are required to open a trading account online?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Below are the documents required to open a trading account online, it is helpful to keep them handy when you try to open a trading account online.
    Address Proof
    PAN Card
    Cancelled Cheque
    Photograph
    Signature on a White Paper
    Income Proof
    
    Note: These must be individual documents and they need to be self-attested before they are uploaded."
        }
      },{
        "@type": "Question",
        "name": "Is it necessary to provide power of Attorney (POA) while opening a trading account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, it is necessary to provide Power of Attorney to your broker. This is part of the account opening procedure and can be directly downloaded from the site. The POA is a critical document so it has to be filled and the attested hard copy has to be mailed to Choice Broking’s HQ. Below is the address,
    Courier Address: Courier Address: Choice International Limited,
    Sunil Patodia Tower,J B Nagar,
    Andheri (East), Mumbai 400099.,Maharastra"
        }
      },{
        "@type": "Question",
        "name": "Is it Safe to Open a Trading Account Online?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "It’s alright to be concerned about security when it comes to money, but the regulators that govern the market have very strict guidelines about maintaining and operating demat accounts. The technology employed is safe and done in a way that makes Trading/Demat Accounts virtually unhackable.
    It’s completely safe to open a trading account online."
        }
      },{
        "@type": "Question",
        "name": "How much money can I add to my trading account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "There are limits as such to the maximum amount that can be added to your account. You will need to check with your back regarding transfers to Demat/ Trading accounts."
        }
      },{
        "@type": "Question",
        "name": "Can I use the same trading account for all segments?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Absolutely, Stocks, Currencies, commodities and F&O, you can use the same account for trading in all segments."
        }
      }]
    }
    </script>
    ` },

  "open-brokerage-account": {
    'title': "Open Zero Brokerage Account in India - Choice", 'lang': "en", "content": "Open a free brokerage account online with Choice. Best brokerage account in India with free AMC for the first year, low DP charges, free research advisory & hasselfree account opening process.",
    "link": 'https://choiceindia.com/open-brokerage-account', 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "How much time is required to open a brokerage account online?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If you have all your documents handy, it should take 4-5 minutes to open a brokerage account online."
        }
      },{
        "@type": "Question",
        "name": "What are the charges at Choice for Opening a brokerage account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "There are no charges for opening a Brokerage Account at Choice."
        }
      },{
        "@type": "Question",
        "name": "Are any AMC charges applied on the brokerage account opened with Choice?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When you open a brokerage account with Choice, you get free AMC charges for the first year. After, the first year you can select from the below 3 plans:
    
    Rs.200/- + GST Yearly Plan
    Rs.1500/- + GST - Lifetime AMC Covered
    Rs.3000/- Full Refundable AMC (Full amount will be refunded when you close the account with us)
    
    *In case no scheme is selected by you then by default AMC as per the annual plan will be charged at the end of the first year.
    
    
    For a Corporate Account, the AMC charges applicable from the 1st year are – 1000/- + GST per Annum Plan."
        }
      },{
        "@type": "Question",
        "name": "Is it necessary to send a POA to activate a brokerage account with Choice?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, it is not necessary to send a POA to activate a brokerage account with Choice.
    
    However, You will need to send a signed copy of your Power of Attorney (POA) to the Choice Head Office after opening a brokerage account.
    
    
    The POA will give the authorization to sell your shares from your Demat account without the need to authorize them online all the time whenever you sell them.
    
    
    Choice Head Office Address:
    Choice House, Shree Shakambhari Corporate Park
    Plot No. 156-158, J B Nagar, Andheri (East)
    Mumbai, Maharashtra - 400099"
        }
      },{
        "@type": "Question",
        "name": "Can I use the same brokerage account for all segments?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, you can use the same brokerage account for trading in all segments."
        }
      },{
        "@type": "Question",
        "name": "Which documents are required for opening a brokerage account online with Choice?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You are required to provide the following documents to open a brokerage account online with Choice.
    A Valid Proof of Identity(Your proof of address can include any of the following):
    
    1. Aadhar Card
    2. Voter ID card
    3. Passport
    4. Driving License
    A Valid Proof of Income(If you want to trade in the F&O segment you have to submit any of these following documents):
    1. A copy of IT returns submitted to the IT department
    2. A copy of annual income audited by a CA
    3. Last 6 months bank statement
    4. Demat account statement with a DP
    5. Net worth certificate
    6. Copy of your Salary slip and Form 16 (for salaried employees)
    
    A Valid Proof of your corresponding Bank Account
    1. A canceled cheque of the bank to be linked with your Trading Account
    2. Bank passbook with the IFSC code clearly printed on it"
        }
      },{
        "@type": "Question",
        "name": "Can I open a free brokerage account online with Choice?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, you can open a free brokerage account online with Choice.
    You must be 18 years of age and above
    You must be a resident of India
    You must have all the documents required to open a brokerage account online"
        }
      }]
    }
    </script>
    ` },

  "nri-demat-account": { 'title': "Open NRI Demat Account Online in India at Choice", 'lang': "en", "content": "Open NRI Demat Account Online in India at Choice with Free Research with Highest Success Ratio, Lowest Brokerage Charges & Lowest Account Opening Fee.", "link": "https://choiceindia.com/nri-demat-account", "faqscript": ``, 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  // "corporate-demat-account": {
  //   'title': "Open a Corporate Demat Account Online with Choice", 'lang': "en", "content": "Open a Corporate Demat account online with Choice.  Get an advanced trading platform, the lowest dp charges and dedicated relationship manager, free call for trade facility and more.",
  //   "link": 'https://choiceindia.com/corporate-demat-account', 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
  //   "faqscript": `<script type="application/ld+json">
  //    {
  //     "@context": "https://schema.org",
  //      "@type": "FAQPage",
  //      "mainEntity": [{
  //        "@type": "Question",
  //        "name": "What is a Company or Corporate Demat Account?",
  //        "acceptedAnswer": {
  //          "@type": "Answer",
  //          "text": "Companies and corporations can also participate in the stock market with a Corporate Demat Account."
  //        }
  //      },{
  //        "@type": "Question",
  //        "name": "Can a Demat and Trading account be opened for a Company in India?",
  //        "acceptedAnswer": {
  //          "@type": "Answer",
  //          "text": "Yes, Indian companies can open Demat and Trading accounts too. These are called Corporate Demat/Trading accounts."
  //        }
  //      },{
  //        "@type": "Question",
  //        "name": "What is the procedure to open a demat account for Company?",
  //        "acceptedAnswer": {
  //          "@type": "Answer",
  //          "text": "Below is the procedure to open corporate demat accounts,
  //    1. Fill Corporate Demat account forms
  //    2. KYC forms of each account holder
  //    3. Aadhar and Photos of account holders (signed)
  //    4. Pan Card copies of each account holder and company
  //    5. Address proofs of each account holder and company
  //    6. Cancelled cheques of account holders or last 3 months bank statement
  //    7. Balance sheet of the partnership of last 2 FY (new companies will have to provide IT returns declaration
  //    8. Duly filled annexures
  //    9. FATCA Declaration"
  //        }
  //      },{
  //        "@type": "Question",
  //        "name": "Which documents are required for a Corporate Or Company demat account?",
  //        "acceptedAnswer": {
  //          "@type": "Answer",
  //          "text": "Below documents are required for a corporate demat account,
  //    1. Aadhar and Photos of account holders (signed)
  //    2. Pan Card copies of each account holder and company
  //    3. Address proofs of each account holder and company
  //    4. Cancelled cheques of account holders or last 3 months bank statement
  //    5. Balance sheet of the partnership of last 2 FY (new companies will have to provide IT returns declaration
  //    6. Duly filled annexures
  //    7. FATCA Declaration"
  //        }
  //      },{
  //        "@type": "Question",
  //        "name": "How Much Time is Required to Open a Corporate Demat Account?",
  //        "acceptedAnswer": {
  //          "@type": "Answer",
  //          "text": "If all the documents are in order, it takes 2-3 working days for the account to be active."
  //        }
  //      }]
  //    }
  //    </script>
  //    ` },

  "minor-demat-account": {
    'title': "Minor Demat Account Opening Online for Free with Choice", 'lang': "en", "content": "Open a free minor Demat account online with Choice within a few minutes and start investing in the stock market for your children’s future.",
    "link": 'https://choiceindia.com/minor-demat-account', 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is a Minor Demat Account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When a Demat account is opened in the name of the Minor; it’s known as Minor Demat Account. The account will be operated by the natural guardian (parents) or court-appointed guardian."
        }
      },{
        "@type": "Question",
        "name": "Can minors open demat accounts in India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, minors can have a demat account opened, but it will be operated by a guardian until the minor becomes a major."
        }
      },{
        "@type": "Question",
        "name": "What is the procedure to open a demat account for Minor?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The procedure remains the same as opening a regular demat account.
    1. Fill the standard demat account form by Guardian
    2. Two KYC forms need to be submitted, one of the Guardian which will collect the bank details, salary range etc. 2nd KYC collects the minor’s basic details to connect it with the guardian, 2nd KYC has to be signed by the Guardian.
    3. Pan and Aadhar card of the minor has to be provided along with the guardian’s documents"
        }
      },{
        "@type": "Question",
        "name": "What are the documents required to open a Minor Demat account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The process of a Minor Demat Account required documents for Identification Proof, Proof of Address, A Photocopy for Age Verification, Applicants’ Photographs such as -
    
    PAN Card of the Guardian
    PAN Card of the Minor
    Proof of Birth of the Minor (Birth Certificate, School Leaving Certificate)
    Proof of Identity of the Minor (Aadhar Card)
    Proof of Address of the Minor (Electricity Bill, Ration Card)"
        }
      },{
        "@type": "Question",
        "name": "Can minors operate Demat and Trading accounts?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Minors cannot operate Demat or Trading accounts, these will be operated only by the guardian until the minor comes of age."
        }
      },{
        "@type": "Question",
        "name": "How to take ownership of Demat Account after minor turns major?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "After the minor turns to major, he has to go through the same procedure that is required to open a new demat account to take over the ownership.
    
    Note: Minor demat accounts become inoperative when he/she turns 18. The broker will intimate you regarding this."
        }
      },{
        "@type": "Question",
        "name": "How much time is required to open a demat account for minors?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If the KYC is clear, it will take 2-3 working days for the account to be active."
        }
      },{
        "@type": "Question",
        "name": "Who is eligible for opening a Minor Demat account in India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Any Indian citizen of India, regardless of age, can own a Minor Demat Account. In India, anyone can legally register a minor Demat account. The minor is unable to buy or sell shares. They will need the father/mother or guardian to function their account. For opening a Demat account a minor must have a valid PAN Card."
        }
      },{
        "@type": "Question",
        "name": "How to invest in the stock market when you’re less than 18 years old?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "For minors under the age of 18, natural guardians (parents) or court-appointed guardians can demat account for them.
    
    Note: Minor Demat accounts can only be used by guardians."
        }
      },{
        "@type": "Question",
        "name": "Can we buy shares in the Minor Demat account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A trading account in the minor's name may be formed only for the purpose of selling stocks that the minor has acquired through IPO, inheritance, corporate action, or off-market transfers. However, until the minor becomes a major, the natural guardian will be in charge of the account.
    A minor is not permitted to enter into a buy or sale agreement with a stockbroker. A trading account in the name of a minor can be opened only for the purpose of investments and selling securities owned by the minor."
        }
      },{
        "@type": "Question",
        "name": "What are the restrictions on the Minor Demat account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A Minor Demat account has some restrictions/limitations such as -
    
    Certain segments are prohibited: A Minor account holder is not permitted to trade in -
    1. Intraday Trading
    2. Currency Derivatives (F&O)
    3. Equity Derivative (F&O)
    
    Trading Account Restriction: A minor's Demat account cannot be linked to their Trading account.
    
    No Joint Account: A minor is not permitted to participate in a Joint Demat account with his parents/guardians.
    
    Transaction Limits: You can only buy or sell delivery shares."
        }
      },{
        "@type": "Question",
        "name": "What to do when a minor becomes a major?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When a minor reaches the age of 18, the Minor Demat Account expires. Then you must create a separate Demat account for the minor. It is possible to transfer old assets to this new account. The account can be used again, but the minor must sign a new contract with the depository participant (DP)."
        }
      },{
        "@type": "Question",
        "name": "Is any additional fee or charges applicable on the Minor Demat account?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Some standard charges are applicable to a Minor Demat Account. There can be an additional Safety or Custodian Charge charged for a Minor Demat Account."
        }
      }]
    }
    </script>
    ` },

  "hindi/open-demat-trading-account": {
    'title': "मुफ़्त डीमैट खाता खोलें | डीमैट अकाउंट पर प्रथम वर्ष के लिए निःशुल्क AMC", 'lang': "hi", "content": "चॉइस के साथ नि: शुल्क ऑनलाइन डीमैट खाता खोलें। हमारी पेपरलेस डीमैट अकाउंट खोलने की प्रक्रिया, 15 मिनट के भीतर आपका खाता खोल देगी।", "link": "https://choiceindia.com/hindi/open-demat-trading-account", 'link1': "https://choiceindia.com/open-free-demat-account", 'link2': "https://choiceindia.com/hindi/open-demat-trading-account", 'link3': "https://choiceindia.com/marathi/open-demat-account", "link4": "https://choiceindia.com/open-free-demat-account","link5": "https://choiceindia.com/open-free-demat-account","link6": "https://choiceindia.com/open-free-demat-account", "href1": "en-in", "href2": "hi-in", "href3": "mr-in", "href4": "x-default",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "डीमैट खाते को सक्रिय होने में कितना समय लगेगा?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "दस्तावेज़ जमा करने के 4 घंटे के भीतर आपका डीमैट अकाउंट सक्रिय हो जाएगा। यदि दस्तावेज़ीकरण में कोई कमी है, तो चॉइस की सहायता टीम आपसे संपर्क करेगी। एक बार आपका खाता सक्रिय हो जाने पर आपको चॉइस की ओर से एक ईमेल प्राप्त होगा जिसमें आपको इसके बारे में सूचित किया जाएगा।"
        }
      },{
        "@type": "Question",
        "name": "डीमैट खाता खोलने के लिए किन दस्तावेजों की आवश्यकता होती है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "चॉइस के साथ डीमैट खाता खोलने के लिए निम्नलिखित दस्तावेजों की आवश्यकता होगी:
    पैन कार्ड
    रद्द किया गया चेक (जो MICR कोड को कैप्चर करता है)
    पासपोर्ट के आकार की तस्वीर
    आधार कार्ड
    आय प्रमाण"
        }
      },{
        "@type": "Question",
        "name": "पावर ऑफ अटॉर्नी (पीओए) क्या है और इसकी आवश्यकता क्यों है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "पावर ऑफ अटॉर्नी एक दस्तावेज है जो हमें आपके शेयरों को बेचने पर आपके डीमैट खाते से डेबिट करने का अधिकार देता है। हालांकि, जब तक आप पीओए जमा नहीं करते, तब तक आप अपनी होल्डिंग बेचने के लिए ईडीआईएस सुविधा का लाभ उठा सकते हैं। इसके लिए आपको किसी भी होल्डिंग के विक्रय लेनदेन को करने से पहले प्रतिदिन एक बार अपनी होल्डिंग्स को अधिकृत करने की आवश्यकता होगी। हम अनुशंसा करते हैं कि आप हमें अपने पीओए की एक हस्ताक्षरित कॉपी भेजें चूंकि यह आपको हर समय ऑनलाइन अधिकृत करने की आवश्यकता के बिना अपने शेयरों को बेचने अनुमति देता है | पीओए की एक कॉपी आपको आपके पंजीकृत ईमेल आईडी पर ईमेल की जाती है, जिस पर आपको हस्ताक्षर करने और हमें कोरियर करने की आवश्यकता होती है। आप हमें हस्ताक्षरित पीओए हमारी वेबसाइट पर दिए गए हमारे मुख्यालय के पते पर भेज सकते हैं | https://choiceindia.com"
        }
      },{
        "@type": "Question",
        "name": "मुझे पावर ऑफ अटॉर्नी कहां कुरियर करना चाहिए?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "आप पीओए की हस्ताक्षरित कॉपी को नीचे दिए गए हमारे प्रधान कार्यालय के पते पर कुरियर से भेज सकते हैं।
    चॉइस इंटरनेशनल लिमिटेड,
    सुनील पटोदिया टावर,
    जे बी नगर, अंधेरी (पूर्व), मुंबई 400099।"
        }
      },{
        "@type": "Question",
        "name": "मैं अपने डीमैट को दूसरे ब्रोकर से चॉइस में कैसे बदलूं?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "आपको हमें अपने पहले के डीपी की सीएमएल (क्लाइंट मास्टर लिस्ट) कि कॉपी प्रदान करनी होगी और हमारे साथ एक नया डीमैट खाता खोलना होगा।"
        }
      },{
        "@type": "Question",
        "name": "क्या मैं अपने ट्रेडिंग खाते में कई डीमैट खातों को मैप कर सकता हूं?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "हां, आप अलग-अलग डीपी के साथ भी कई डीमैट खातों को अपने ट्रेडिंग खाते से लिंक कर सकते हैं, लेकिन वे सभी आपके नाम पर होने चाहिए। हालांकि, ध्यान देने वाली एक महत्वपूर्ण बात यह है कि सभी लिंक किए गए डीमैट खातों से आप पे इन/प्लेज आदि के लिए शेयर दे सकते हैं; लेकिन ट्रेडिंग खाते से भुगतान केवल प्राथमिक डीमैट खाते में जमा किया जाएगा।"
        }
      },{
        "@type": "Question",
        "name": "मैं दूसरे डीमैट खाते से शेयर अपने चॉइस डीमैट खाते में कैसे भेज सकता हूं?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "आपको अपने पिछले डीपी को ऑफ मार्केट डिलीवरी इंस्ट्रक्शन स्लिप (डीआईएस) प्रदान करने की आवश्यकता होगी, जिसमें चॉइस इक्विटी ब्रोकिंग प्राइवेट लिमिटेड में आपके द्वारा रखे गए डीमैट खाते के लक्ष्य डीपी आईडी का उल्लेख होगा।"
        }
      },{
        "@type": "Question",
        "name": "क्या शेयर बाजार में निवेश के लिए डीमैट अकाउंट खोलना आवश्यक है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "यदि आप इक्विटी में निवेश करने जा रहे हैं तो डीमैट अकाउंट अनिवार्य है। डीमैट खाते आपके शेयरों को इलेक्ट्रॉनिक रूप में रखते हैं। यदि आप केवल वायदा और विकल्प में निवेश करने जा रहे हैं तो डीमैट अकाउंट की आवश्यकता नहीं है।"
        }
      },{
        "@type": "Question",
        "name": "क्या मैं चॉइस के साथ अपने पिछले डीमैट खाते का उपयोग कर सकता हूं?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "2 विकल्प हैं, या तो आप चॉइस के साथ एक नया डीमैट अकाउंट खोलें और पुराने डीमैट से सभी शेयरों को स्थानांतरित करें, या पुराने डीमैट को नए में मर्ज करें।
    आप शेयरों के मैन्युअल या ऑनलाइन हस्तांतरण का विकल्प चुन सकते हैं।
    मैनुअल ट्रांसफर
    1. पता लगाएं कि कौनसा डीपी (डिपॉजिटरी पार्टिसिपेंट) शेयर धारण कर रहा है, भारत में 2 डिपॉजिटरी हैं, नेशनल सिक्योरिटीज डिपॉजिटरी लिमिटेड (एनएसडीएल) और सेंट्रल डिपॉजिटरी सर्विसेज लिमिटेड (सीडीएसएल) |
    2.यदि आपका नया डीमैट उसी डीपी के पास है तो यह इंटर-डिपॉजिटरी ट्रांसफर होगा, और यदि यह अलग है; यह एक इंट्रा-डिपॉजिटरी ट्रांसफर होगा |
    3.इसके बाद, अपने पुराने डीमैट अकाउंट की डीपी द्वारा प्रदान की गई डिलीवरी इंस्ट्रक्शन स्लिप (डीआईएस) भरें। 'मोड ऑफ ट्रांसफर' फील्ड में, कृपया चुनें कि ट्रांसफर इंटर-डिपॉजिटरी है या इंट्रा-डिपॉजिटरी। यह महत्वपूर्ण है |
    4. सभी आवश्यक विवरण भरें जैसे शेयरों के नाम, मात्रा और उनके आईएसआईएन नंबर
    5. नए खाते की 16-वर्ण आईडी दर्ज करें
    6. पुराने ब्रोकर को हस्ताक्षरित डीआईएस जमा करें और पावती पर्ची लें
    7. शेयरों को 3-5 कार्य दिवसों में नए खाते में स्थानांतरित कर दिया जाएगा
    ऑनलाइन स्थानांतरण
    यह विकल्प बहुत आसान है, बस सीडीएसएल वेबसाइट पर जाएं और सभी आवश्यक विवरणों के साथ पंजीकरण करें आपको एक फॉर्म भरना होगा और 'प्रिंट फॉर्म' विकल्प का उपयोग करना होगा; यह सत्यापन प्रक्रिया शुरू करने के लिए सीडीएसएल को सूचित करता है |
    सत्यापन पूरा होने के बाद, आपके खाते में लॉगिन विवरण आपकी ईमेल आईडी पर भेजा जाएगा सीडीएसएल वेबसाइट से अपने खाते में लॉगिन करें और शेयरों को नए खाते में स्थानांतरित करना शुरू करें"
        }
      },{
        "@type": "Question",
        "name": "क्या मैं डीमैट खाते में पैसा रख सकता हूँ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "बिल्कुल, आप डीमैट/ट्रेडिंग खाते में पैसा रख सकते हैं। हालांकि, ट्रेडिंग/निवेश के लिए केवल उतनी ही राशि रखने की सलाह दी जाती है जितनी की आवश्यकता होगी।"
        }
      },{
        "@type": "Question",
        "name": "क्या ऑनलाइन डीमैट अकाउंट खोलना सुरक्षित है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "डीमैट अकाउंट ऑनलाइन खोलना बिल्कुल सुरक्षित है लेकिन इसके लिए सही ब्रोकर चुनना आवश्यक है।"
        }
      },{
        "@type": "Question",
        "name": "क्या मैं स्टॉक, मुद्रा, डेरिवेटिव और कमोडिटी ट्रेडिंग के लिए उसी डीमैट या ट्रेडिंग खाते का उपयोग कर सकता हूं?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "हां, आप डीमैट खाते का उपयोग किसी भी परिसंपत्ति वर्ग में निवेश के लिए कर सकते हैं।"
        }
      },{
        "@type": "Question",
        "name": "डीमैट खाते को सक्रिय होने में कितना समय लगेगा?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "यदि दस्तावेज़ क्रम में हैं, तो डीमैट खाता 24-48 कार्य घंटों में सक्रिय हो जाएगा।"
        }
      },{
        "@type": "Question",
        "name": "पेपरलेस डीमैट अकाउंट क्या है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "डीमैट अकाउंट खोलने के इस विकल्प में किसी भी दस्तावेज को भौतिक रूप से जमा करने की आवश्यकता नहीं है। सभी दस्तावेज़ ऑनलाइन जमा किए जाते हैं और इन्हें पूरा करने में केवल 10 मिनट लगते हैं!"
        }
      }]
    }
    </script>
    ` },

  "open-trading-account-marathi": {
    'title': "Choice - Stock Broker & Full Service Stock Brokerage Firm in India", "content": "Choice (formerly Choice Broking) is one of the best stock brokers in India. Start online trading in stocks, commodities, currencies, derivatives with India's leading full-service brokerage firm.", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
    "lang": 'mr', "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "डिमॅट खाते सक्रिय होण्यासाठी किती वेळ लागेल?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "तुम्ही कागदपत्रे सादर केल्यानंतर चार तासांच्या आत तुमचे डिमॅट खाते सक्रिय होते. तुम्ही सादर केलेल्या कागदपत्रांमध्ये काही तफावत किंवा त्रूटी आढळल्यास आमचे चॉईस ब्रोकिंगचे प्रतिनिधी तुमच्याशी संपर्क साधून त्याची पूर्तता करेल. तसेच, तुमचे खाते सक्रिय झाल्यानंतर, तुम्हाला चॉईस ब्रोकिंगकडून एक ईमेल येईल, त्यामध्ये तुम्हाला खाते सक्रिय झाल्याबद्दलची माहिती दिली जाईल."
        }
      },{
        "@type": "Question",
        "name": "डिमॅट खाते उघडण्यासाठी कोणती कागदपत्रे आवश्यक आहेत?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "चॉईस ब्रोकिंगसह तुमचे डिमॅट खाते उघडण्यासाठी खालील कागदपत्रांची आवश्यकता असेल;
    पॅन कार्ड
    कॅन्सल चेक (MIRC कोडसाठी)
    पासपोर्ट साईज फोटो
    आधार कार्ड
    उत्पन्नाचा दाखला"
        }
      },{
        "@type": "Question",
        "name": "पॉवर ऑफ अँटर्नी म्हणजे काय आणि त्याची गरज का आहे?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "पॉवर ऑफ अँटर्नी हा एक असा दस्ताऐवज आहे, ज्याद्वारे तुम्हाला तुमच्या शेअर्सची विक्री झाल्यावर डिमॅट खात्यातून डेबिट करण्याचे अधिकार दिले जातात. त्यामुळे जोपर्यंत तुम्ही तुमची पॉवर ऑफ अँटर्नी सादर करत नाही, तोपर्यंत तुम्हाला तुमची होल्डिंग विक्री करण्यासाठी eDIS सुविधेचा लाभ घेता येतो. यासाठी कोणतेही होल्डिंग्सची विक्री करण्यापूर्वी तुम्ही तुमचे होल्डिंग अधिकृत करणे गरजेचे असते. म्हणजेच एकप्रकारे तुमच्या POA ची स्वाक्षरी केलेली प्रत ही तुम्हाला तुमचे होल्डिंग कोणत्याही अडचणींशिवाय विकण्यास सक्षम करते.
    प्POA साठी त्याची एक प्रत तुम्हाला तुमच्या ईमेल आयडीवर पाठवली जाईल. त्यावर तुम्ही तुमची स्वाक्षरी करुन आम्हाला कुरिअर करणे गरजेचे आहे. तुम्ही स्वाक्षरी केलेली POA ची प्रत आमच्या संकेतस्थळावर -https://choiceindia.com/ नमूद केलेल्या मुख्य कार्यालयाच्या पत्त्यावर पाठवावी."
        }
      },{
        "@type": "Question",
        "name": "मी पॉवर ऑफ अँटर्नी कोठे कुरिअर करावी?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "तुम्ही स्वाक्षरी केलेली POA ची प्रत आमच्या मुख्यालयाच्या खाली दिलेल्या पत्त्यावर कुरिअर करावी
    चॉईस इंटरनॅशनल लिमिटेड,
    सुनील पतोडिया टॉवर,
    जे.बी. नगर, अंधेरी (पूर्व) मुंबई ४०००९९"
        }
      },{
        "@type": "Question",
        "name": "चॉईस ब्रोकिंगसह डिमॅट खाते उघडण्यासाठी काही शुल्क आकारले जाते का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "चॉईस ब्रोकिंगसह डिमॅट खाते उघडण्यासाठी काही शुल्क आकारले जाते का?"
        }
      },{
        "@type": "Question",
        "name": "डिमॅट खाते न उघडता मी ऑनलाईन ट्रेडिंग खाते कसे ओपन करु शकतो?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "एनएसई/ बीएसई सेक्यूरिटीजसाठी तुमच्या ट्रेडिंग खात्यासह डिमॅट खाते असणे आवश्यक असते. चलन (Currency) आणि कमोडिटीसाठी डिमॅट खात्याची आवश्यकता नसते. त्यामुळे जर तुमच्या ट्रेडिंगच्या गरजा मर्यादित असतील, तर तुम्हाला ट्रेडिंग खाते निवडणे सोईचे राहिल. तसेच ब्रोकरसोबतच्या कागदपत्रांमध्ये त्याचा स्पष्ट उल्लेख करणे अनिवार्य असते. यासंबंधी आधिक माहितीसाठी care@choiceindia.com ला एकदा आवश्य भेट द्या. किंवा आमच्या ग्राहक सेवा टीमशी संपर्क साधू शकता. आमचे प्रतिनिधी तुम्हाला योग्य ते मार्गदर्शन करुन तुमच्या सर्व शंकांचे निरसन करतील."
        }
      },{
        "@type": "Question",
        "name": "मी माझे जुने डिमॅट दुसऱ्या ब्रोकरकडून चॉईस ब्रोकिंगमध्ये कसे बदलू शकतो?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "तुम्हाला तुमच्या जुन्या डीपीची CML (क्लायंट मास्टर लिस्ट) देऊन, आमच्याकडे नवीन डिमॅट खाते ओपन करणे आवश्यक आहे."
        }
      },{
        "@type": "Question",
        "name": "मी माझ्या ट्रेडिंग खात्यावर एकपेक्षा जास्त डिमॅट खाती मॅप करु शकतो का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "हो. तुम्ही तुमच्या ट्रेडिंग खात्याशी एकपेक्षा जास्त डिमॅट खाती लिंक करु शकता. तेही वेगवेगळ्या डीपीसह. पण ती सर्व खाती तुमच्या नावावर असणे आवश्यक आहे. पण महत्त्वाची गोष्ट म्हणजे, सर्व लिंक केलेल्या डिमॅट खात्यांमधून तुम्ही पे इन किंवा प्लेज आदींसाठी शेअर देऊ शकता. पण ट्रेडिंग खात्यातील पेआउट केवळ"
        }
      },{
        "@type": "Question",
        "name": "मी दुसऱ्या एखाद्या डिमॅट खात्यातून माझ्या चॉईस ब्रोकिंगच्या डिमॅट खात्यात शेअर्स कसे हस्तांतरित करु शकतो?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "चॉईस इक्विटी ब्रोकिंग प्रायव्हेट लिमिटेडमध्ये तुम्ही घेत असलेल्या डिमॅट खात्याच्या टार्गेट डीपी आयडीचा उल्लेख करावा लागेल. त्यानंतर तुम्हाला तुमच्या आधीच्या डीपीला ऑफ मार्केट डिलिव्हरी इन्स्ट्रक्शन स्पिल (DIS) प्रदान करणे आवश्यक असते."
        }
      },{
        "@type": "Question",
        "name": "शेअर बाजारातील गुंतवणुकीसाठी डिमॅट खाते उघडणे आवश्यक आहे का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "जर तुम्ही इक्विटीमध्ये ट्रेडिंग/ गुंतवणूक करणार असाल, तर डिमॅट खाते अनिवार्य आहे. डिमॅट खात्यांमध्ये तुमचे शेअर इलेक्ट्रॉनिक स्वरुपात सुरक्षित ठेवले जातात. जर तुम्ही फक्त फ्युचर्स आणि ऑप्शन्समध्ये ट्रेडिंग करणा असाल, तर डिमॅटची आवश्यकता नाही."
        }
      },{
        "@type": "Question",
        "name": "मी माझे जुने डिमॅट खाते चॉईससह वापरू शकतो का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "यासाठी दोन पर्याय आहेत. एकतर तुम्ही चॉईस ब्रोकिंगमध्ये नवीन डिमॅट खाते ओपन करा. आणि जुन्या डिमॅटमधून सर्व शेअर हस्तांतरित करा किंवा ते चॉईसच्या खात्यात विलीन करा. यासाठी तुम्ही शेअर हस्तांतरणासाठी मॅन्यूअल किंवा ऑनलाईन पर्यांयाची निवड करु शकता.
    मॅन्यूअल केल्यास;
    १. कोणता डीपी (डिपॉझिटरी पार्टिसिपंट) समभाग घेत आहे. ते तपासा. कारण आपल्याकडे दोनप्रकारचे डिपॉझिटर्स आहेत. पहिले म्हणजे नॅशनल सेक्युरिटीज डिपॉझिटरी लिमिटेड (NSDL) आणि दुसरे म्हणजे सेंट्रल डिपॉझिटरी सर्व्हिसेस लिमिटेड (CDSL)
    २. जर तुमचे नवीन डिमॅट त्याचप्रकारच्या डीपीकडे असेल, तर ते इंटर डिपॉझिटरी असेल. जर ते दोन्हीही वेगवेगळे असतील, तर इंट्रा डिपॉझिटरी हस्तांतरण असेल.
    ३. तुमच्या जुन्या खात्याच्या DP द्वारे प्रदान केलेली डिलिव्हरी इंस्ट्रक्शन स्लिप (DIS) भरा. त्यानंतर मोड ऑफ ट्रान्सफरमध्ये जाऊन हस्तांतरण इंटर डिपॉझिटरी कि इंट्रा डिपॉझिटरी आहे हे तपासून तो पर्याय निवडा.
    ४. समभागांची नावे, प्रमाण आणि त्यांचे ISIN क्रमांक आदी आवश्यक तपशील भरा.
    ५. नवीन खात्याचा १६ डिजीट आयडी भरा.
    ६. जुन्या ब्रोकरकडे स्वाक्षरी केलेली डीआयएस सादर करुन पोचपावती घ्या.
    ७. तीन-पाच दिवसांत शेअर्स नवीन खात्यात हस्तांतरित होतील.
    ऑनलाईन ट्रान्सफर
    हा अतिशय सोपा पर्याय आहे. केवळ CDSL च्या वेबसाईटला भेट द्या, आणि आवश्यक सर्व तपशीलांसह नोंदणी करा. त्यासाठी तुम्हाला दिलेला एक फॉर्म भरावा लागेल. आणि प्रिंट फॉर्म हा पर्याय निवडा. हे CDSL ला हस्तांतरणाची प्रक्रिया सुरु करण्यासाठी सुचित करते. यासाठीची पडताळणी पूर्ण झाल्यानंतर तुमच्या खात्याचा लॉगइन तपशील तुमच्या ईमेल आयडीवर पाठवला जाईल. त्यानंतर CDSL वेबसाईटवरुन तुमच्या खात्यात लॉगइन करा. आणि नवीन खात्यात शेअर हस्तांतरित करण्यास सुरुवात करा."
        }
      },{
        "@type": "Question",
        "name": "मी डिमॅट खात्यात पैसे ठेवू शकतो का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "नक्कीच, तुम्ही तुमच्या डिमॅट/ ट्रेडिंग खात्यात आवश्य पैसे ठेवू शकता. पण आम्ही तुम्हाला ट्रेडिंग/ गुंतवणुकीसाठी आवश्यक तेवढीच रक्कम तुमच्या डिमॅट खात्यात ठेवण्याचा सल्ला देऊ."
        }
      },{
        "@type": "Question",
        "name": "ऑनलाईन डिमॅट खाते उघडणे सुरक्षित आहे का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "ऑनलाईन डिमॅट खाते ओपन करणे पूर्णपणे सुरक्षित आहे. मात्र यासाठी योग्य ब्रोकर निवडणे आवश्यक आहे."
        }
      },{
        "@type": "Question",
        "name": "ट्रेडिंग खात्यातून पैसे जमा करणे आणि काढणे यासाठी मर्यादा आहेत का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "पैसे जमा करणे किंवा काढणे, यासाठी कोणतीही मर्यादा नाही. मात्र जेव्हा एकपेक्षा जास्त व्यवहारांची आवश्यकता असेल, तेव्हा MPS/NEFT/RTGS साठी एका व्यवहारासाठी कमाल रक्कम बँकेद्वारे ठरवली जाते. UPI द्वारे ट्रान्सफरची मर्यादा एक लाख इतकी आहे. अनेक ब्रोकरच्या बाबतीत पैसे काढण्याची मर्यादा नसते. पण त्या ब्रोकरची संपूर्ण माहिती घेऊन ती पडताळून घ्या."
        }
      },{
        "@type": "Question",
        "name": "मी स्टॉक, चलन, डेरिव्हेटिव्ह आणि कमोडिटी ट्रेडिंगसाठी समान डिमॅट किंवा ट्रेडिंग खाते वापरु शकतो का?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "नक्कीच, तुम्ही तुमचे खाते कोणत्याही मालमत्ता वर्गाता (assets) ट्रेडिंग/ गुंतवणुकीसाठी वापरु शकता."
        }
      },{
        "@type": "Question",
        "name": "चॉईसद्वारे डिमॅट खाते उघडण्याचे किती शूल्क आकारले जाते?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "ते पूर्णपणे मोफत असून, चॉईसद्वारे डिमॅट खाते ओपन करण्याचे कोणतेही शुल्क आकारले जात नाही."
        }
      },{
        "@type": "Question",
        "name": "ऑनलाईन डिमॅट आणि ट्रेडिंग खाते उघडण्यासाठी पात्रता आणि निकष काय आहेत?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "वास्तविक, वयाचा असा कोणताही निकष नाही. म्हणजे १८ वर्षांखालील व्यक्तीदेखील डिमॅट आणि ट्रेडिंग खाते ओपन करु शकतात. पण अल्पवयीन असल्याने त्याच्या पालकांकडेच त्याचे पालकत्व असते. पण तरीही डिमॅट खाते ओपन करण्यासाठी काही महत्त्वाचे निकष आहेत.
    १. भारतीय नागरीक असावा.
    २. वयोमर्यादा १८
    ३. पॅन कार्ड
    ४. वैध पत्त्याचा पुरावा (आधार कार्ड, मतदान ओळखपत्र, पासपोर्ट, ड्रायव्हिंग लायसन्स, युटिलिटी बिल)
    भारतात डिमॅट खाते ओपन करण्यासाठी तुम्हाला ओळखपत्र, पत्ता आणि उत्पन्न ITR पुराव्यासह फोटो देणे आवश्यक असते."
        }
      },{
        "@type": "Question",
        "name": "डिमॅट खाते सक्रिय होण्यासाठी किती वेळ लागेल?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "कागदपत्रे व्यवस्थित असल्यास, डिमॅट खाते २४ ते ४८ तासांत सक्रिय होते."
        }
      },{
        "@type": "Question",
        "name": "पेपरलेस डिमॅट खाते उघडणे म्हणजे काय?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "डिमॅट खाते उघडण्याच्या पेपरलेस पर्यायामध्ये कोणतीही कागदपत्रांचे भौतिक (physical) सादरीकरण नसते. सर्व कागदपत्रे ऑनलाईन सादर करावी लागतात. तसेच ही प्रक्रिया पूर्ण होण्यासाठी १० मिनिटांचा वेळ लागतो."
        }
      }]
    }
    </script>
    `},

  "research": { 'title': "Choice - Stock Broker & Full Service Stock Brokerage Firm in India", 'lang': "en", "content": "Choice (formerly Choice Broking) is one of the best stock brokers in India. Start online trading in stocks, commodities, currencies, derivatives with India's leading full-service brokerage firm.", "faqscript": ``, 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "authorised-person": {
    'title': "Become an Authorised Person (AP) in Stock Market with Choice", 'lang': "en", "content": "Become an Authorised Person (AP) in stock market with Choice. Earn upto ₹ 1000 per account incentive, highest revenue sharing, timely payouts & more.",
    'link': 'https://choiceindia.com/authorised-person', 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "Who can become a Choice Authorised Person?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Anyone from across India can be a Choice Authorised Person. Eligibility and qualification criteria are minimal, all we are looking for is willingness and passion."
            }
          },{
            "@type": "Question",
            "name": "How much does a Authorised Person earn in India?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Authorised Person can select from fixed revenue per client or Brokerage sharing options. Sky's the limit as far as earnings are concerned, since we have the entire suite of financial products and customization options."
            }
          },{
            "@type": "Question",
            "name": "What Are the Documents required to Become Choice Authorised Person?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The following documents are required to complete Authorised Person registration with us.
        
        PAN Card
        Cancelled Cheque (that captures the MICR Code)
        Passport Size Photograph
        Aadhaar Card
        Income Proof"
            }
          }]
        }
        </script>
    ` },

  "remisier": {
    'title': "Become a Remisier in Stock Market with Choice & Earn Big", 'lang': "en", "content": "Become a Remisier in stock market with Choice. Start your own Share Market Remisier business with highest revenue sharing, upto ₹1000 per account incentive",
    "link": 'https://choiceindia.com/remisier', 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "Who can become a Choice Remisier?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Anyone from across India can be a Choice Remisier. Eligibility and qualification criteria are minimal, all we are looking for is willingness and passion."
        }
      },{
        "@type": "Question",
        "name": "How much does a Remisier earn in India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Remisiers can select from fixed revenue per client or Brokerage sharing options. Sky's the limit as far as earnings are concerned, since we have the entire suite of financial products and customization options."
        }
      },{
        "@type": "Question",
        "name": "What Are the Documents required to Become Choice Remisier?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The following documents are required to complete Remisier registration with us.
    
    PAN Card
    Cancelled Cheque (that captures the MICR Code)
    Passport Size Photograph
    Aadhaar Card
    Income Proof"
        }
      }]
    }
    </script>
    ` },

  "demat-account": {
    'title': "Demat Account - Meaning, Types, Features, Uses and Benefits", 'lang': "en", 'content': "What is Demat account? Know demat account meaning, it's features and benefits, types of demat account, charges, opening process, required documents, and more.", 'link': "https://choiceindia.com/demat-account", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
     "faqscript": `
     <script type="application/ld+json">
{ 
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is the eligibility to have a demat account?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A Demat account can be opened by Indian residents, non-resident Indians (NRIs), and foreign investors through registered depository participants (DPs)."
    }
  },{
    "@type": "Question",
    "name": "Is Demat account safe?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Demat being a digitalised account is regulated by SEBI, and registered under NSDL and CDSL. However being associated with a reputed broker like choice ensures safety."
    }
  },{
    "@type": "Question",
    "name": "Can I have multiple Demat accounts?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can have multiple Demat accounts, but there are regulatory restrictions. Each account must have a different purpose or ownership structure."
    }
  },{
    "@type": "Question",
    "name": "What is the eligibility to open a demat account?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Age - There is no minimum age criteria. Individuals above 18 yers are eligible for a regular demat account while the ones below 18 can open a minor demat account. Their parents or guardians will be in-charge of the account until the minor comes to age.
Citizenship - Must be Indian Citizen residing in India
Other requirements - Have valid pan card & a valid address proof (Aadhar, Voter ID, Passport, Driving License)"
    }
  }]
}
</script> `,
    "faqscript1": `
 <script type="application/ld+json">
{
  "@context": "https://schema.org/",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://choiceindia.com/demat-account"
  },
  "headline": "Demat Account - Meaning, Types, Features, Uses and Benefits",
  "description": "Detailed guide over a Demat Account, its features, benefits, types, charges levied, how it works, documents required, choosing a broker and how to open a Demat account.",
  "image": {
    "@type": "ImageObject",
    "url": "https://i.ytimg.com/vi_webp/3u0VZAHMwO0/maxresdefault.webp",
    "width": "1278",
    "height": "720"
  },
  "author": {
    "@type": "Organization",
    "name": "Team Choice"
  },
  "publisher": {
    "@type": "Organization",
    "name": "Choice India",
    "logo": {
      "@type": "ImageObject",
      "url": "https://choiceindia.com/static/media/choice-logo.924aa461a450ed092f58ea7c3e4a4a9d.svg",
      "width": "150",
      "height": "37"
    }
  },
  "datePublished": "2023-10-17",
  "dateModified": "2021-01-18"
}
</script>`,
    
    "faqscript2": `
  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "What is Demat Account | Introduction of Demat Account in Simple Way",
  "description": "An introduction to demat account, what are shares, how demat account helps to keep them safe",
  "thumbnailUrl": "https://i.ytimg.com/vi_webp/3u0VZAHMwO0/maxresdefault.webp",
  "uploadDate": "2023-10-17",
  "duration": "PT0M59S",
  "contentUrl": "https://choiceindia.com/demat-account",
  "embedUrl": "https://www.youtube.com/embed/3u0VZAHMwO0?si=sqbJJfBthND0ReKo",
  "potentialAction": {
    "@type": "SeekToAction",
    "target": "https://www.youtube.com/watch?v=3u0VZAHMwO0={seek_to_second_number}",
    "startOffset-input": "required name=seek_to_second_number"
  }
}
</script>`  
  
  },

  "info/stratezy": { 'title': "Stratezy - A Free Trading Strategy Builder by Choice", 'lang': "en", "content": "Stratezy - A Free Trading Strategy Builder by Choice. Stratezy is the free-trading strategy builder for intraday and options trades. Optimise your trading strategies and make it lives with Strategy.", "link": "https://choiceindia.com/info/stratezy", "faqscript": ``, 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "baskets": { 'title': "Basket Investing - Invest in Thematic Baskets of Your Choice", 'lang': "en", "content": "Choice FinX Trading app introduces the Basket Investing function to make stock market investing simpler for everyone. To reach your long-term financial goals, explore through our themed baskets.", "link": 'https://choiceindia.com/baskets', "faqscript": ``, 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },

  "share-market-app": {
    'title': "Share Market App in India - Download Choice FinX now!", 'lang': "en", "content": "Explore choice finX, the best share market app in India. Invest in multiple instruments with our share market advanced application online.", "link": "https://choiceindia.com/share-market-app", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
    "faqcript": `<script type="application/ld+json">
     {
      "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": [{
         "@type": "Question",
         "name": "How much time is required to open an account with Choice FinX - Share Market App?",
         "acceptedAnswer": {
           "@type": "Answer",
           "text": "To open an account online with Choice FinX it takes 5-7 minutes if you have all the documents handy."
         }
       },{
         "@type": "Question",
         "name": "What are the charges for opening an account in Choice FinX?",
         "acceptedAnswer": {
           "@type": "Answer",
           "text": "There are no charges for opening an account in the stock market app - Choice FinX."
         }
       },{
         "@type": "Question",
         "name": "What are the documents required to open an account in Choice FinX?",
         "acceptedAnswer": {
           "@type": "Answer",
           "text": "You will require the following documents to open an account in the Choice FinX share market app:
     PAN Card
     - As per the SEBI Regulations, a PAN Card is mandatory for any investment in capital markets.
     Aadhaar Card
     - This is required for a fast and paperless account opening.
     Cancelled Cheque
     - With clearly printed name of the Account Holder, Account Number, IFSC Code, and MICR Code.
     A Photograph
     - KYC requirement as per the SEBI Regulations.
     Signature on White Paper
     - It is a regulatory and operational requirement to cross-reference the signature in case of physically opening an account.
     Income Proof
     - If you wish to trade in futures and options of all Equity, Commodity, and Currency; then Income Proof is a mandatory requirement."
         }
       },{
         "@type": "Question",
         "name": "Can anyone open a free trading account with Choice FinX?",
         "acceptedAnswer": {
           "@type": "Answer",
           "text": "Any individual of 18 years and above can open a free trading account with Choice FinX. However, the individual must hold the required documents to open an account."
         }
       },{
         "@type": "Question",
         "name": "What are the brokerage charges in Choice FinX - Share Market App?",
         "acceptedAnswer": {
           "@type": "Answer",
           "text": "Choice offers the lowest brokerage charges in the industry. Check the brokerage charges on our website."
         }
       },{
         "@type": "Question",
         "name": "Can I trade in all segments using the Choice FinX App?",
         "acceptedAnswer": {
           "@type": "Answer",
           "text": "Yes, you can trade in Equity, Currency, Commodity, and F&O using the Choice FinX app."
         }
       }]
     }
     </script>
     `},

  "sovereign-gold-bonds": {
    'title': "Choice - Stock Broker & Full Service Stock Brokerage Firm in India", 'lang': "en", "content": "Choice (formerly Choice Broking) is one of the best stock brokers in India. Start online trading in stocks, commodities, currencies, derivatives with India's leading full-service brokerage firm.", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "",
    "faqcript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is the minimum and maximum order quantity for a single person?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "For individuals, the minimum order quantity is of 1 gram and maximum is upto 4 kg in a single financial year (March - April)akhs."
        }
      },{
        "@type": "Question",
        "name": "What is the minimum and maximum investment for SGB?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The minimum investment is equivalent to the price of 1 gram of Gold. The bonds are pegged to actual gold, so the price is the price of 999 purity of gold. Maximum investment is upto 4kg for an individual in a single financial year."
        }
      },{
        "@type": "Question",
        "name": "How much interest will be received on this SGB scheme?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The interest rate has been fixed at 2.50% per annum. This is paid half-yearly into your bank account, and the last installment of the interest will be paid along with the principal amount upon maturity."
        }
      },{
        "@type": "Question",
        "name": "Can I Sell Sovereign Gold bonds before maturity?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, absolutely. The total maturity period is 8 years with an exit option in 5th, 6th and 7th year."
        }
      },{
        "@type": "Question",
        "name": "How much tax will I have to pay after selling SGB on maturity?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No tax whatsoever. There is no capital gains tax on these bonds upon maturity and subsequent redemption"
        }
      },{
        "@type": "Question",
        "name": "Is investing in SGB Safe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Since the price of gold fluctuates, the only risk is the reduction of capital if the market price of gold declines. There is no loss of units that the investor has paid for."
        }
      },{
        "@type": "Question",
        "name": "Can I sell partially or full SGB after maturity?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "An intimation is received 1 month in advance of the bond’s maturity. The balance interest amount and the full maturity amount is credited directly into the bank given at the time of buying the bond. Upon maturity you directly receive the proceeds of the investment."
        }
      },{
        "@type": "Question",
        "name": "Are there any charges or fees for SGB application?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The intermediary bank you’re applying for the SGB from will charge some transaction fees."
        }
      },{
        "@type": "Question",
        "name": "Which authorities are issuing these SGB schemes?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sovereign Gold Bonds are offered by the Government of India. The only authorized issuer for these bonds is The Reserve Bank of India. Bonds, however, can be bought from the list of banks here"
        }
      },{
        "@type": "Question",
        "name": "Sovereign Gold Bonds are government gold bond schemes?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, the Government of India in order to monetize the gold reserves, started the ‘Gold Monetisation Scheme’, so these are Gold Bonds offered by the Government."
        }
      }]
    }
    </script>
    `},

  "hindi/trading-account": {
    'title': "मुफ़्त ट्रेडिंग अकाउंट खोलें + प्रथम वर्ष के लिए निःशुल्क AMC", "content": "चॉइस के साथ नि: शुल्क ऑनलाइन ट्रेडिंग अकाउंट खोलें और प्रथम वर्ष के लिए फ्री एएमसी प्राप्त करें। अपने ट्रेडिंग खाते पर कम ब्रोकरेज और डीपी शुल्क प्राप्त करें।",
    "link": 'https://choiceindia.com/hindi/trading-account', 'lang': "hi", 'link1': "https://choiceindia.com/open-trading-account", 'link2': "https://choiceindia.com/hindi/trading-account", 'link3': "https://choiceindia.com/open-trading-account", "link4": "", "link5": "", "link6": "", "href1": "en-in", "href2": "hi-in", "href3": "x-default", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "ऑनलाइन ट्रेडिंग खाता खोलने के लिए कितना समय लगता है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "ऑनलाइन अकाउंट खोलना काफी सुविधाजनक हो गया है। यदि आपके पास सभी आवश्यक दस्तावेज उपलब्ध हैं, और केवाईसी हो गया है, तो खाता 15 मिनट में खोला जा सकता है। अगर केवाईसी नहीं किया गया तो इसमें कुछ समय लग सकता है |"
        }
      },{
        "@type": "Question",
        "name": "क्या ऑनलाइन ट्रेडिंग अकाउंट खोलते समय स्टॉक ब्रोकरों द्वारा कोई शुल्क लगाया जाता है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "अन्य ब्रोकरों के बारे में कहना मुश्किल है, लेकिन चॉइस के साथ ऑनलाइन ट्रेडिंग अकाउंट खोलना बिल्कुल मुफ्त है। चॉइस ट्रेडिंग अकाउंट खोलने के लिए कोई शुल्क नहीं लेता है।"
        }
      },{
        "@type": "Question",
        "name": "चॉइस ट्रेडिंग अकाउंट के लिए एएमसी शुल्क क्या हैं",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "प्रथम वर्ष एएमसी निःशुल्क है, अगले वर्ष से जब भी आप चाहें, चॉइस आपके ट्रेडिंग अकाउंट को उपयोग के लिए तैयार रखने और बनाए रखने के लिए न्यूनतम शुल्क लेता है।
    
    प्रथम वर्ष की एएमसी नि:शुल्क है और फिर इसमें से चुनने के लिए *3 योजनाएं हैं:
    
    रु.200/- + जीएसटी वार्षिक योजना
    रु.1500/- + जीएसटी - लाइफटाइम एएमसी कवर
    3000/- पूर्ण वापसी योग्य एएमसी (चॉइस के साथ खाता बंद करने पर पूरी राशि वापस कर दी जाएगी)"
        }
      },{
        "@type": "Question",
        "name": "ऑनलाइन ट्रेडिंग अकाउंट खोलने के लिए किन दस्तावेजों की आवश्यकता होती है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "ऑनलाइन ट्रेडिंग अकाउंट खोलने के लिए आवश्यक दस्तावेज नीचे दिए गए हैं, जब आप ऑनलाइन ट्रेडिंग खाता खोलने का प्रयास करते हैं तो उन्हें संभाल कर रखना आवश्यक है।
    निवास प्रमाण पत्र
    पैन कार्ड
    रद्द चेक
    फोटो
    श्वेत पत्र पर हस्ताक्षर
    आय प्रमाण
    
    नोट: ये अलग-अलग दस्तावेज होने चाहिए और अपलोड करने से पहले उन्हें स्व-सत्यापित किया जाना चाहिए।"
        }
      },{
        "@type": "Question",
        "name": "क्या ट्रेडिंग खाता खोलते समय पावर ऑफ अटॉर्नी (पीओए) प्रदान करना आवश्यक है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "हां, अपने ब्रोकर को पावर ऑफ अटॉर्नी प्रदान करना आवश्यक है। यह खाता खोलने की प्रक्रिया का हिस्सा है और इसे सीधे साइट से डाउनलोड किया जा सकता है। पीओए एक महत्वपूर्ण दस्तावेज है इसलिए इसे भरना होगा और सत्यापित हार्ड कॉपी को चॉइस के मुख्यालय को मेल करना होगा। नीचे पता है,
    कूरियर पता: चॉइस इंटरनेशनल लिमिटेड,
    सुनील पटोदिया टॉवर, जे बी नगर,
    अंधेरी (पूर्व), मुंबई 400099।, महाराष्ट्र"
        }
      },{
        "@type": "Question",
        "name": "क्या ऑनलाइन ट्रेडिंग अकाउंट खोलना सुरक्षित है?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "जब पैसे की बात आती है तो सुरक्षा के बारे में चिंतित होना ठीक है, लेकिन बाजार को नियंत्रित करने वाले नियामकों के पास ट्रेडिंग अकाउंट को सुरक्षित बनाए रखने और संचालित करने के बारे में बहुत सख्त दिशानिर्देश हैं। नियोजित तकनीक सुरक्षित है और इस तरह से विकसित की गई है कि ट्रेडिंग खातों को हैक करना संभव नहीं है ऑनलाइन ट्रेडिंग खाता खोलना पूरी तरह से सुरक्षित है।"
        }
      },{
        "@type": "Question",
        "name": "मैं अपने ट्रेडिंग खाते में कितना पैसा रख सकता हूं?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "आपके खाते में जोड़ी जा सकने वाली अधिकतम राशि की सीमाएँ हैं। ट्रेडिंग खातों में स्थानांतरण के संबंध में आपको अपने ब्रोकर से संपर्क करना होगा।"
        }
      },{
        "@type": "Question",
        "name": "क्या मैं सभी सेगमेंट के लिए एक ही ट्रेडिंग अकाउंट का उपयोग कर सकता हूं?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "हां, स्टॉक, मुद्रा, कमोडिटी और एफएनओ, आप सभी सेगमेंट में ट्रेडिंग के लिए एक ही खाते का उपयोग कर सकते हैं।"
        }
      }]
    }
    </script>
    `},

  "online-trading-app": {
    'title': "Choice FinX Trading App - Best Indian Online Stock Trading App (Android & iOS)", 'lang': "en", "content": "Simplify trading with the best online trading app in India. Trade across all segments using smart features with Choice FinX trading app. Download now!", 'link': "https://choiceindia.com/online-trading-app", 'link1': "https://choiceindia.com/online-trading-app", 'link2': "https://choiceindia.com/hindi/online-trading-app", 'link3': "https://choiceindia.com/online-trading-app", "link4": "", "link5": "", "link6": "", "href1": "en-in", "href2": "hi-in", "href3": "x-default", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "Can I do trading in all segments with the Choice FinX Trading App?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Choice FinX supports trading in all segments i.e. Equity, Derivatives, Commodity and Currency"
        }
      },{
        "@type": "Question",
        "name": "Is the Choice FinX Trading app safe for trading?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We use comprehensive and proven world-class safety measures to ensure optimum security."
        }
      },{
        "@type": "Question",
        "name": "Can I open a Demat Account from Choice FinX Trading App?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sure, the entire process takes just 10 minutes and is completely paperless!"
        }
      },{
        "@type": "Question",
        "name": "Can I Trade in Future & Options Through a Trading App?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sure, you can trade in F&0 and we also provide intraday research/calls for Futures & Options."
        }
      },{
        "@type": "Question",
        "name": "How to apply for an IPO in Choice FinX Trading App?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Below are the steps to apply for IPOs via Choice FinX,
    
    Go to the IPO Section => IPO Listing => Open IPO
    Tap IPO you want, and directly click on ‘Subscribe’ to apply or view the IPO details
    On the Subscribe Screen/Page, you will be required to enter the number of lots, cut-off price, UPI ID and tap Verify
    Once you select ‘Subscribe’. Your bid will be executed
    You can check the status of your IPO bid in the IPO Transactions section"
        }
      },{
        "@type": "Question",
        "name": "Is Choice FinX Trading App Available in Other Regional Languages?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Choice FinX has options for Hindi, Gujarati, Marathi and Malayalam as of now."
        }
      },{
        "@type": "Question",
        "name": "Can I get research reports in the Choice FinX Trading App?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Our research team enjoys a 78% consistent success ratio, and provides detailed Fundamental and Technical Research for investments/Trading. We also provide intra-day trade calls."
        }
      },{
        "@type": "Question",
        "name": "How can I get Ledger, Cash Deposit, Cash Withdrawal, Profit & Loss statements in Choice FinX?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "All of the above reports and more are available in the ‘Reports’ section of Choice FinX for the current year. For reports of previous years, you can login to the ‘Back office’ section from Choice FinX itself."
        }
      },{
        "@type": "Question",
        "name": "Is Choice FinX Trading App Available on Android & iOS platform?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Choice FinX is available on both Android & iOS."
        }
      }]
    }
    </script>
    `},

  "campaign/free-amc-demat-account": {
    'title': "Open a Free AMC Demat Account + Zero Account Opening Fee", 'lang': "en", "content": "Open a Free Demat Account Online at Choice with Low Brokerage Charges, First Year AMC Free, Up to 25X Margin, Free Research with 78% Success Ratio & more.",
    "link": "https://choiceindia.com/campaign/free-amc-demat-account", 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "", "faqscript": `<script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "How long will it take for the Demat Account to be activated?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Your account will be activated within 4 hours of document submission. If there is any gap in the documentation, the Choice Broking support team will reach out to you. Once your account is activated you will receive an email from Choice Broking informing you about the same."
          }
        },{
          "@type": "Question",
          "name": "What are the documents required to open a Demat Account?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The following documents will be required to open an account for a resident individual with Choice Broking:
      PAN Card
      Cancelled Cheque (that captures the MICR Code)
      Passport Size Photograph
      Aadhaar Card"
          }
        },{
          "@type": "Question",
          "name": "What is the Power of Attorney (PoA) and why is it needed?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The Power of Attorney is a document that gives us the authorization to debit your shares from your Demat Account whenever you sell them. However, till the time you do not submit a POA, you can avail the eDIS facility to sell your holdings. This will require you to authorise your holdings once daily before doing any holding's sell transactions. It is recommended to send a signed copy of your POA since it enables you to sell your holdings seamlessly without the need to authorise online all the time. A copy of the POA gets emailed to you on your registered email ID, which needs to be physically signed and couriered to us. You can send us the signed POA to our head-office address mentioned on our website - https://choiceindia.com"
          }
        },{
          "@type": "Question",
          "name": "Where should I courier the Power of Attorney?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can courier the signed copy of the POA to our Head Office address mentioned below.
      Choice International Limited,
      Sunil Patodia Tower,
      J B Nagar, Andheri (East), Mumbai 400099."
          }
        },{
          "@type": "Question",
          "name": "Are there any charges for Demat Account opening with Choice Broking?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can open a low brokerage Demat Account with Choice Broking at zero account opening charges"
          }
        },{
          "@type": "Question",
          "name": "How do I open a Trading Account Online without opening a Demat Account?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "NSE/BSE mandates a Demat with your Trading Account for securities. Currency and Commodities do not require a Demat Account, so if your trading requirements are restricted to that; a Trading Account can be opted for. This will have to be specified in the documentation process with the broker. For more information regarding the same, you can contact our customer care team at care@choiceindia.com"
          }
        },{
          "@type": "Question",
          "name": "How do I switch my Demat from another broker to Choice Broking?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You will be required to provide us with the CML (Client Master List) Copy of your earlier DP and open a new Demat Account with us."
          }
        },{
          "@type": "Question",
          "name": "Can I map multiple Demat Accounts to my Trading Account?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, you can link multiple Demat Accounts to your Trading Account, even with different DP's, but they should all be in your name. However, an important thing to note is that from all the linked Demat Accounts you can give shares for Pay In/Pledge, etc; but the payout from the Trading Account will only get credited to the primary Demat Account."
          }
        },{
          "@type": "Question",
          "name": "How can I transfer shares from another Demat Account to my Choice Broking Demat Account?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You will be required to provide the Off Market Delivery Instruction Slip (DIS) to your previous DP, mentioning the target DP ID of the Demat Account you are holding in Choice Equity Broking Private Limited."
          }
        },{
          "@type": "Question",
          "name": "Is opening a Demat account necessary for stock market investment?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Demat account is mandatory if you are going to trade / invest in equities. Demat accounts hold your shares in electronic form. If you are going to simply trade in futures and options then Demat is not required."
          }
        },{
          "@type": "Question",
          "name": "Can I use my previous Demat Account with Choice?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "There are 2 options, either you open a new Demat account with Choice and transfer all shares from the old Demat, or merge the old Demat into the new one.
      You can opt for manual or online transfer of shares.
      Manual Transfer
      Ascertain which DP (Depository Participant) is holding the shares, there are 2 depositories in India, National Securities Depository Limited (NSDL) and Central Depository Services Limited (CDSL)
      If your new Demat is with the same DP then it will be an inter-depository transfer, and if it's different; it will be an intra-depository transfer
      Next, fill the Delivery Instruction slip (DIS) provided by the DP of your old account. In the 'Mode of Transfer' field, please select whether the transfer is inter-depository or intra-depository. This is crucial
      Fill all required details like names of shares, quantity and their ISIN numbers
      Enter the 16-character ID of the new account
      Submit the signed DIS to old broker and take acknowledgement slip
      Shares will be transferred to new account in 3-5 working days
      Online Transfer
      This option is much simpler, just visit the CDSL website and register with all required details
      You will be required to fill a form and use the 'Print Form' option; this notifies CDSL to start the verification process
      After verification is completed, Login details to your account will be sent on your email id
      Login to your account from CDSL website and start transferring shares to the new account"
          }
        },{
          "@type": "Question",
          "name": "Can I hold money in a Demat Account?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Absolutely, you can keep money in the Demat/Trading account. It is, however, advisable to hold only the amount that will be required for trading/investing."
          }
        },{
          "@type": "Question",
          "name": "Is Opening a Demat Account Online Safe?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It's absolutely safe to open a Demat account online but choosing the right broker is essential for this."
          }
        },{
          "@type": "Question",
          "name": "Is there a limit for Deposit and Withdrawal Money from a Trading Account?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "There is no limit as such for depositing or withdrawing money, however, it will need multiple transactions. For MPS/NEFT/RTGS/ the maximum amount allowed in a single transaction is decided by the bank. UPI transfers have a limit of 1 lakh per transaction. In the case of most brokers, there is no withdrawal limit but do check once with your broker."
          }
        },{
          "@type": "Question",
          "name": "Can I use the same Demat or Trading account for Stock, Currency, Derivatives and Commodity Trading?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, you can use the same account for trading/investing in any asset class."
          }
        },{
          "@type": "Question",
          "name": "How much Demat Account Opening Fee charged by Choice?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It's free. There is no Demat A/c opening fee charged by Choice."
          }
        },{
          "@type": "Question",
          "name": "What are the eligibility criteria for opening an online Demat & Trading Account?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "As far as age is concerned, there is no minimum age criterion i.e., under 18 can also have Demat & Trading accounts opened. The parents or guardians will be in-charge of the account until the minor comes of age.
      Below are the common requirements,
      Must be Indian Citizen residing in India
      Must be 18 years of age
      Have valid Pan Card
      Valid Address Proof (Aadhar, Voter ID, Passport, Driving License, utility bills (not more than 3 months old))
      You will be required to provide recent photos along with proof of Identity, Address and Income (ITR) to open a Demat account in India."
          }
        },{
          "@type": "Question",
          "name": "How long will it take for the Demat account to be activated?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "If the documents are in order, the Demat account will be activated in 24 – 48 working hours."
          }
        },{
          "@type": "Question",
          "name": "What is Paperless Demat account opening?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "In this option for Demat account opening, there is no physical submission of any document involved. All documents are submitted online and take only 10 minutes to complete!"
          }
        }]
      }
      </script>
      `
  },



  // "app": {'title': "", "content": "", "faqscript": ``},

  "hindi/online-trading-app": {
    'title': "फिनएक्स ट्रेडिंग ऐप - भारत का श्रेष्ठ ऑनलाइन मोबाइल ट्रेडिंग ऐप",
    "content": "फिनएक्स ट्रेडिंग ऐप भारत का श्रेष्ठ मोबाइल ट्रेडिंग ऐप है। ऑनलाइन ट्रेडिंग ऐप -  फिनएक्स (एंड्रॉइड और आईओएस) डाउनलोड करें और शेयर बाज़ार में तेजी निवेश करें।", "link": "https://choiceindia.com/hindi/online-trading-app", 'lang': "hi", 'link1': "https://choiceindia.com/online-trading-app", 'link2': "https://choiceindia.com/hindi/online-trading-app", 'link3': "https://choiceindia.com/online-trading-app", "link4": "", "link5": "", "link6": "", "href1": "en-in", "href2": "hi-in", "href3": "x-default", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "क्या मैं फिनएक्स ट्रेडिंग ऐप के साथ सभी सेगमेंट में ट्रेडिंग कर सकता हूं?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "हां, फिनएक्स सभी सेगमेंट में ट्रेडिंग का समर्थन करता है जो कि इक्विटी, डेरिवेटिव्स, कमोडिटी और करेंसी है|"
          }
        },{
          "@type": "Question",
          "name": "क्या फिनएक्स ट्रेडिंग ऐप ट्रेडिंग के लिए सुरक्षित है?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "हम सुरक्षा सुनिश्चित करने के लिए व्यापक और विश्व स्तरीय टेक्नालजी का उपयोग करते हैं|"
          }
        },{
          "@type": "Question",
          "name": "क्या मैं फिनएक्स ट्रेडिंग ऐप से डीमैट खाता खोल सकता हूं?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "हां, पूरी प्रक्रिया में सिर्फ 10 मिनट लगते हैं और यह पूरी तरह से पेपरलेस है!"
          }
        },{
          "@type": "Question",
          "name": "क्या मैं ट्रेडिंग ऐप के माध्यम से फ्यूचर और ऑप्शंस में ट्रेड कर सकता हूं?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "हाँ, आप F&0 में ट्रेड कर सकते हैं और हम फ्यूचर्स और ऑप्शंस के लिए इंट्राडे रिसर्च/कॉल भी प्रदान करते हैं।"
          }
        },{
          "@type": "Question",
          "name": "फिनएक्स ट्रेडिंग ऐप में आईपीओ के लिए आवेदन कैसे करें?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "फिनएक्स के माध्यम से आईपीओ के लिए आवेदन करने के चरण नीचे दिए गए हैं - 
      आईपीओ अनुभाग पर जाएं => आईपीओ लिस्टिंग => ओपन आईपीओ
       IPO पर टैप करें, और IPO विवरण लागू करने या देखने के लिए सीधे 'सदस्यता लें' पर क्लिक करें|
      सब्स्क्राइब स्क्रीन/पेज पर, आपको लॉट की संख्या, कट-ऑफ मूल्य, यूपीआई आईडी दर्ज करनी होगी और सत्यापित करें पर टैप करना होगा।
      एक बार जब आप 'सदस्यता लें' का चयन करें। आपकी बोली क्रियान्वित की जाएगी
      आप आईपीओ लेनदेन अनुभाग में अपनी आईपीओ बोली की स्थिति की जांच कर सकते हैं|"
          }
        },{
          "@type": "Question",
          "name": "क्या फिनएक्स ट्रेडिंग ऐप अन्य भाषाओं में उपलब्ध है?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "हां, फिनएक्स के पास फिलहाल हिंदी, गुजराती, मराठी और मलयालम के विकल्प हैं।"
          }
        },{
          "@type": "Question",
          "name": "क्या मुझे जिफ़ी ट्रेडिंग ऐप में शोध रिपोर्ट मिल सकती है?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "हमारी शोध टीम को 78% लगातार सफलता अनुपात प्राप्त है, और ऐप निवेश / व्यापार के लिए विस्तृत मौलिक और तकनीकी अनुसंधान प्रदान करता है। हम इंट्रा-डे ट्रेड कॉल भी प्रदान करते हैं।"
          }
        },{
          "@type": "Question",
          "name": "क्या फिनएक्स ट्रेडिंग ऐप एंड्रॉइड और आईओएस प्लेटफॉर्म पर उपलब्ध है?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "हां, फिनएक्स एंड्रॉयड और आईओएस दोनों पर उपलब्ध है।"
          }
        }]
      }
      </script>
      
  ` },

  "partner-assets/emitra": { 'title': "Basket Investing - चॉइस पार्टनर ई-मित्र की प्रचार सामग्री", 'lang': "en", "content": "", "link": 'https://choiceindia.com/partner-assests/emitra/', "faqscript": ``, 'link1': "", 'link2': "", 'link3': "", "link4": "", "link5": "", "link6": "", "href1": "", "href2": "", "href3": "", "href4": "", "href5": "", "href6": "" },
  "mutual-fund-distributor": {
    'title': "Become a Mutual Fund Distributor with Choice", 'lang': "en",
    "content": "Become a Mutual Fund Distributor online in few steps. Know how to become a MF distributor & empanel with Choice to start your mutual fund agent business today!",
    "link": 'https://choiceindia.com/mutual-fund-distributor', "faqscript": ``, 'link1': "https://choiceindia.com/mutual-fund-distributor", 'link2': "https://choiceindia.com/hindi/mutual-fund-distributor", 'link3': "https://choiceindia.com/mutual-fund-distributor", "link4": "", "link5": "", "link6": "", "href1": "en-in", "href2": "hi-in", "href3": "x-default", "href4": "", "href5": "", "href6": "",
    "faqscript": `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Is it necessary to clear the NISM exam to become a mutual fund distributor in India?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To become a mutual fund distributor, one must gain knowledge about the products and intricacies of mutual funds. Hence, obtaining an AMFI certification is necessary."
      }
    },{
      "@type": "Question",
      "name": "What are the career prospects of being a mutual fund distributor?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The future prospects of mutual funds in India are promising due to the growing MF industry and investors, the increase in demand for mutual funds, and the number of available mutual fund distributors in India. Opting for this career has many advantages such as being your own boss, earning passive income, etc. Also, one has the flexibility to earn income as per his/her goals as the payment is subjective to selling maximum schemes."
      }
    },{
      "@type": "Question",
      "name": "What is the process for senior citizens to become a mutual fund distributor?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Mutual Funds provide senior citizens with the opportunity to channel their energies into creating a source of income. For this, senior citizens are individuals who qualify under the criteria stipulated by SEBI vide its Circular No. CIR/ MFD/DF/5/2010 dated June 24, 2010, i.e., a person who has attained the age of 50 years as of May 31, 2010, OR a person who has at least 10 years experience in the securities market as of May 31, 2010."
      }
    },{
      "@type": "Question",
      "name": "How to become a mutual fund distributor?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Anyone holding an AMFI certificate is eligible to become a Mutual Fund distributor. In order to become a mutual fund distributor with Choice, one can fill in the registration details. An expert team shall assist them via customercare@investica.com or +91 8080808875, once the details have been verified and approved. Note: Investica is a part of Choice Wealth Pvt Ltd. and registered under the ARN Code: 78908"
      }
    },{
      "@type": "Question",
      "name": "In what form do mutual fund distributors earn revenue?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Mutual fund distributors earn revenue in the form of commissions. These commissions are a percentage of the investment made by the clients they bring in. The more clients they have and the more those clients invest, the higher their earnings."
      }
    },{
      "@type": "Question",
      "name": "Is the mutual fund distribution business profitable?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, the mutual fund distribution business can be highly profitable. The profitability depends on the individual's ability to sell and the number of clients they can acquire. The more you sell, the more you earn."
      }
    },{
      "@type": "Question",
      "name": "With which agency are mutual fund distributors registered?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Mutual fund distributors are registered with the Association of Mutual Funds in India (AMFI) and must hold a valid ARN (AMFI Registration Number) issued by the National Institute of Securities Markets (NISM)."
      }
    },{
      "@type": "Question",
      "name": "How much does a mutual fund distributor earn?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The earning potential of a mutual fund distributor is not capped and depends entirely on the individual's ability to sell. The more mutual fund units you sell, the higher your earnings."
      }
    },{
      "@type": "Question",
      "name": "When will I get the commission on the investment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Commissions are released on a monthly basis at Choice. You can track the status of your clients' payments with the CRM access provided to you once you are registered with Choice."
      }
    },{
      "@type": "Question",
      "name": "What is the minimum and maximum age to apply for ARN?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The minimum age to apply for an ARN and become a Mutual Fund distributor is 18 years. There is no maximum age limit."
      }
    },{
      "@type": "Question",
      "name": "Why is the ARN Code Necessary?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The ARN Code is mandated by regulators for every mutual fund distributor. It ensures that all distributors are qualified and authorized to sell mutual fund units."
      }
    }]
  }
  </script>`

  },

  "hindi/mutual-fund-distributor": {
    'title': "चॉइस म्यूचुअल फंड वितरक/ डिस्ट्रीब्यूटर बनें",
    "content": "चॉइस म्यूचुअल फंड वितरक बनें और आय का एक आजीवन स्रोत बनाएं। म्यूचुअल फंड डिस्ट्रीब्यूटर बनने के साथ अपने ग्राहकों को कई वित्तीय प्रोडक्ट्स की पेशकश भी करें।",
    "link": 'https://choiceindia.com/hindi/mutual-fund-distributor', "faqscript": ``, 'lang': "hi", 'link1': "https://choiceindia.com/mutual-fund-distributor", 'link2': "https://choiceindia.com/hindi/mutual-fund-distributor", 'link3': "https://choiceindia.com/mutual-fund-distributor", "link4": "", "link5": "", "link6": "", "href1": "en-in", "href2": "hi-in", "href3": "x-default", "href4": "", "href5": "", "href6": ""
  },

  "gujarati/sub-broker-franchise": {
    'title': "ડિપોઝિટ વિના સબ બ્રોકર ફ્રેન્ચાઇઝ - ચોઇસ સબ બ્રોકર બનો",
    "content": "ચોઇસ સાથે સબ બ્રોકર બનો. ડિપોઝિટ વિના સબ બ્રોકરશિપ બિઝનેસ અથવા ફ્રેન્ચાઇઝ ખોલો* અને ક્લાયન્ટના સમગ્ર ટ્રેડિંગ આયુષ્ય માટે સર્વોચ્ચ બ્રોકરેજ શેરિંગ મેળવો.",
    "link": 'https://choiceindia.com/gujarati/sub-broker-franchise', "lang": 'gu', "faqscript": ``, 'link1': "https://choiceindia.com/sub-broker-franchise", 'link2': "https://choiceindia.com/hindi/sub-broker-franchise", 'link3': "https://choiceindia.com/gujarati/sub-broker-franchise", "link4": "https://choiceindia.com/telugu/sub-broker-franchise", "link5": "https://choiceindia.com/marathi/sub-broker-franchise", "link6": "https://choiceindia.com/sub-broker-franchise", "href1": "en-in", "href2": "hi-in", "href3": "gu-in", "href4": "te-in", "href5": "mr-in", "href6": "x-default"
  },

  "marathi/sub-broker-franchise": {
    'title': "चॉइस सब ब्रोकर बना आणि डिपॉझिटशिवाय सब ब्रोकर फ्रँचायझी सुरू करा",
    "content": "चॉइस सब ब्रोकर बना. डिपॉझिटशिवाय सब ब्रोकर फ्रँचायझी सुरू करा, तसेच सर्वाधिक महसूल वाटप आणि आजीवन उत्पन्न स्रोत मिळावा.",
    "link": 'https://choiceindia.com/marathi/sub-broker-franchise', "lang": 'mr', "faqscript": ``, 'link1': "https://choiceindia.com/sub-broker-franchise", 'link2': "https://choiceindia.com/hindi/sub-broker-franchise", 'link3': "https://choiceindia.com/gujarati/sub-broker-franchise", "link4": "https://choiceindia.com/telugu/sub-broker-franchise", "link5": "https://choiceindia.com/marathi/sub-broker-franchise", "link6": "https://choiceindia.com/sub-broker-franchise", "href1": "en-in", "href2": "hi-in", "href3": "gu-in", "href4": "te-in", "href5": "mr-in", "href6": "x-default"
  },

  "telugu/sub-broker-franchise": {
    'title': "డిపాజిట్ లేకుండా సబ్ బ్రోకర్ ఫ్రాంచైజ్ - ఛాయిస్ సబ్ బ్రోకర్ అవ్వండి",
    "content": "ఛాయిస్తో సబ్ బ్రోకర్ అవ్వండి. డిపాజిట్ లేకుండా సబ్ బ్రోకర్‌షిప్ వ్యాపారం లేదా ఫ్రాంచైజీని తెరవండి* & క్లయింట్ యొక్క మొత్తం ట్రేడింగ్ జీవితకాలం కోసం మొత్తం బ్రోకరేజీలో 75% వరకు పొందండి.", "link": 'https://choiceindia.com/telugu/sub-broker-franchise', "lang": 'te', "faqscript": ``, 'link1': "https://choiceindia.com/sub-broker-franchise", 'link2': "https://choiceindia.com/hindi/sub-broker-franchise", 'link3': "https://choiceindia.com/gujarati/sub-broker-franchise", "link4": "https://choiceindia.com/telugu/sub-broker-franchise", "link5": "https://choiceindia.com/marathi/sub-broker-franchise", "link6": "https://choiceindia.com/sub-broker-franchise", "href1": "en-in", "href2": "hi-in", "href3": "gu-in", "href4": "te-in", "href5": "mr-in", "href6": "x-default"
  },

  "hindi/mutual-funds-investment": {
    'title': "म्यूचुअल फंड में ऑनलाइन निवेश करें च्वाइस के साथ",
    "content": "चॉइस के साथ, आप भारत में ऑनलाइन म्यूचुअल फंड में निवेश करना शुरू कर सकते हैं। चॉइस म्यूचुअल फंड निवेश मंच सभी के लिए म्यूचुअल फंड में निवेश को आसान बनाता है।",
    "link": 'https://choiceindia.com/hindi/mutual-funds-investment', "lang": 'hi', "faqscript": ``, 'link1': "https://choiceindia.com/mutual-funds-investment", 'link2': "https://choiceindia.com/hindi/mutual-funds-investment", 'link3': "https://choiceindia.com/mutual-funds-investment", "link4": "", "link5": "", "link6": "", "href1": "en-in", "href2": "hi-in", "href3": "x-default", "href4": "", "href5": "", "href6": ""
  },
  "etf-exchange-traded-funds": {
    'title': "What is ETF? Meaning and How to Invest in ETF?",
    "content": "Do you wish to learn more about exchange-traded funds? Visit to learn more about ETF meaning, investing in ETF, ETF vs. Mutual Funds, ETF vs. Stocks, etc.",
    "link": 'https://choiceindia.com/etf-exchange-traded-funds', "lang": 'en', "faqscript": ``
  },

  "intraday-charges": {
    'title': "Save Intraday Charges on Trades with Choice",
    "content": "Pay no additional brokerage fees on intraday charges. Start intraday trading with Choice and save up to 33% on brokerage. Save money right now!",
    "link": 'https://choiceindia.com/intraday-charges', "lang": 'en', "faqscript": ``
  },

  "best-stocks-to-buy": {
    'title': "Best Stocks to Buy Today in India - Choice", 'lang': "en", "content": "Know the best stocks to buy today in India. Get the latest list of shares to buy today in India recommended by experts at Choice.",
    "link": "https://choiceindia.com/best-stocks-to-buy"
  },
  "best-intraday-stocks-to-buy": {
    'title': "Best Intraday Stocks to Buy Today - Choice", 'lang': "en", "content": "Trade only in the best intraday stocks selected by our experts to ensure successful trades. Explore the top day trading hot stocks for today.",
    "link": "https://choiceindia.com/best-intraday-stocks-to-buy"
  },
  "best-short-term-stocks-to-buy": {
    'title': "Best Short Term Stocks to Buy Today - Choice", 'lang': "en", "content":"Get the list of best short term stocks to buy now! Check the top picks for best short term stocks to gain benefits of the price movements at short periods.",
    "link": "https://choiceindia.com/best-short-term-stocks-to-buy"
  },
  "best-stocks-for-long-term-investment": {
    'title': "Best Stocks to Buy for Long Term Investment - Choice", 'lang': "en", "content": "Get handpicked best stocks for long term investment finely researched by our experts. Improvise your portifolio with the top stocks to hold for long term.",
    "link": "https://choiceindia.com/best-stocks-for-long-term-investment"
  },

  "loan": {
    'title': "Apply for Loan Online & Get Instant Loan within Few Hours", 'lang': "en", "content": "Apply for loan online at Choice FinServ. Get a instant loan for your business, buying a commercial vehicle, flexi credit, invoice financing and channel financing at attractive interest rates.",
    "link": "https://choiceindia.com/loan"
  },
  "business-loan": {
    'title': "Apply for Business Loan & Get Instant Business Loan Online", 'lang': "en", "content": "Apply for business loan online at Choice FinServ. Get a instant business loan upto 25 Lakhs at attractive interest rates, low processing fees. Apply now.",
    "link": "https://choiceindia.com/business-loan"
  },
  "term-business-loan": {
    'title': "Apply for Term Business Loan Online at Choice FinServ", 'lang': "en", "content": "Apply online at Choice FinServ for a term business loan. Get a fast business loan with low processing costs and attractive interest rates. Visit now to apply.",
    "link": "https://choiceindia.com/term-business-loan"
  },
  "flexi-credit-loan": {
    'title': "Apply Flexi Credit Loan for Business Online at Choice FinServ", 'lang': "en", "content": "Visit Choice FinServ to apply for a Flexi Credit Loan online. Get a flexible credit loan quickly into your bank account with low processing costs and competitive interest rates. Visit to apply instantly.",
    "link": "https://choiceindia.com/flexi-credit-loan"
  },

  "cfpl-policies": {
    'title': "CFPL Policies - Choice International Limited", 'lang': "en", "content": "Read the latest CFPL policies at Choice International Limited's official website.",
    "link": "https://choiceindia.com/cfpl-policies"

  },
  "invoice-financing": {
    'title': "Apply for Invoice Financing & Get Funds within 48 Hours", 'lang': "en", "content": "Apply online at Choice FinServ for invoice financing. Get quick invoice financing within just as 48 hours with low processing fees and appealing interest rates. To apply, go to the website right now.",
    "link": "https://choiceindia.com/invoice-financing"
  },
  "channel-financing": {
    'title': "Get Channel Financing for SME's Online within 48 Hours", 'lang': "en", "content": "Apply for channel financing online at Choice FinServ. Get quick supply chain financing within just as 48 hours at competitive interest rates. Visit the website right now to apply.",
    "link": "https://choiceindia.com/channel-financing"
  },
  "commercial-vehicle-loan": {
    'title': "Get Commercial Vehicle Loan Online at Choice FinServ", 'lang': "en", "content": "Apply a commercial vehicle loan online at Choice FinServ. Get a quick commercial car loan with minimal processing fees and attractive interest rates. Visit instantly to apply.",
    "link": "https://choiceindia.com/commercial-vehicle-loan"
  },
  "solar-financing": {
    'title': "Solar Financing - Get Solar Panels on Loan for Businesses", 'lang': "en", "content": "Apply for solar financing at Choice FinServ at best rates. Get a loan for purchasing solar panels for MSME and small businesses upto 25 Lakhs within 2 days.",
    "link": "https://choiceindia.com/solar-financing"
  },
  "offer-document": {
    "title": "Public Issue Offer Document of Equity and Debt - Choice",
    'lang': "en",
    'content': "Check out the latest public issue document of equity and debt at Choice's official website.",
    "link": "https://choiceindia.com/offer-document"
  },
  "stock-broker-in-mumbai": {
    "title": "Choice - Stock Broker in Mumbai | Official Branch",
    'lang': "en",
    'content': "Stock Broker in Mumbai. Get address, phone number, photos, maps location, timings & nearby branches of Choice. Connect with us at +022 67079999.",
    "link": "https://choiceindia.com/stock-broker-in-mumbai"
  },
  "individual-loan": {
    "title": "Get Individual Loan Online at Choice FinServ",
    'lang': "en",
    'content': "Individual loans are available through Choice FinServ. Get a quick individual loan with low processing fees and low interest rates. Visit instantly to apply.",
    "link": "https://choiceindia.com/individual-loan"

  },

  "stock-broker-in-bangalore": {
    "title": "Choice - Stock Broker in Bangalore | Official Branch",
    'lang': "en",
    'content': "Stock Broker in Bangalore. Get address, phone number, photos, maps location, timings & nearby branches of Choice. Connect with us at +91 9886062043.",
    "link": "https://choiceindia.com/stock-broker-in-bangalore"
  },

  "stock-broker-in-chennai": {
    "title": "Choice - Stock Broker in Chennai | Official Branch",
    'lang': "en",
    'content': "Stock Broker in Chennai. Get address, phone number, photos, maps location, timings & nearby branches of Choice. Connect with us at +91 9884580688.",
    "link": "https://choiceindia.com/stock-broker-in-chennai"
  },

  "stock-broker-in-hyderabad": {
    "title": "Choice - Stock Broker in Hyderabad | Official Branch",
    'lang': "en",
    'content': "Stock Broker in Hyderabad. Get address, phone number, photos, maps location, timings & nearby branches of Choice. Connect with us at +02267079999.",
    "link": "https://choiceindia.com/stock-broker-in-hyderabad"
  },

  "stock-broker-in-delhi": {
    "title": "Choice - Stock Broker in Delhi | Official Branch",
    'lang': "en",
    'content': "Stock Broker in Delhi. Get address, phone number, photos, maps location, timings & nearby branches of Choice. Connect with us at +91 9322512424.",
    "link": "https://choiceindia.com/stock-broker-in-delhi"
  },
  "lending-partners": {
    "title": "Lending Partners with Choice Finserv",
    'lang': "en",
    'content': "In our portfolio, we're working with lending institutions. Visit to view a complete list of our partners for lending.",
    "link": "https://choiceindia.com/lending-partners"
  },
  "stock-market-holidays": {
    "title": "Stock Market Holidays 2024 - List of Indian Share Market Holidays",
    'lang': "en",
    'content': "Check out the stock market holidays in 2024 on Choice. Stay updated with Indian share market holidays in 2024 & plan your investment accordingly.",
    "link": "https://choiceindia.com/stock-market-holidays"
  },
  "nse-holidays": {
    "title": "NSE Holidays 2024 List - Check NSE Trading Holidays of 2024",
    'lang': "en",
    'content': "Find a comprehensive list of NSE holidays in 2024 on Choice. Visit to download a PDF of the 2024 NSE trading holidays.",
    "link": "https://choiceindia.com/nse-holidays"
  },
  "bse-holidays": {
    "title": "BSE Holidays 2024 List - Check BSE Trading Holidays of 2024",
    'lang': "en",
    'content': "Check out the complete list of BSE holidays for 2024 on Choice. To download a PDF of the 2024 BSE trading holidays, visit Choice India.",
    "link": "https://choiceindia.com/bse-holidays"
  },
  "mcx-ncdex-holidays": {
    "title": "MCX Holidays 2024 List - Check MCX & NCDEX Trading Holidays in 2024",
    'lang': "en",
    'content': "Check the list of MCX and NCDEX holidays for 2024. Visit Choice India to download the complete trading holidays of MCX and NCDEX in pdf format.",
    "link": "https://choiceindia.com/mcx-ncdex-holidays"
  },


  "about-choice-finserv": {
    "title": " About Choice Finserv",
    'lang': "en",
    'content': "A part of the Choice Group, Choice Finserv aims to support the capital needs of India's MSME sector by offering a variety of loans.",
    "link": "https://choiceindia.com/about-choice-finserv"
  },
  "loan/privacy-policy": {
    "title": " Privacy Policy - Choice Finserv",
    'lang': "en",
    'content': "Visit us to know more about our privacy policy",
    "link": "https://choiceindia.com/loan/privacy-policy"
  },
  "upcoming-agm": {
    "title": "List of Upcoming AGM Dates of Companies. AGM Calendar 2024",
    'lang': "en",
    'content': "Get the list of upcoming AGM/EGM dates of companies at Choice. Be updated with upcoming AGM Meeting dates of listed companies, its benefits & how to use them.",
    "link": "https://choiceindia.com/upcoming-agm"
  },
  "upcoming-board-meeting": {
    "title": "Upcoming Board Meetings - Latest & Forthcoming Board Meetings Dates",
    'lang': "en",
    'content': "Find out the list of upcoming board meetings at Choice. Get the updates on forthcoming & latest company board meeting dates of NSE/BSE listed companies.",
    "link": "https://choiceindia.com/upcoming-board-meeting"
  },
  "upcoming-bonus-shares": {
    "title": "Upcoming Bonus Shares in 2024 - List of Bonus Shares to Buy",
    'lang': "en",
    'content': "Check out upcoming bonus shares in 2024 on Choice. Get the list of all upcoming bonus shares companies in India likely to declare/issue bonus shares.",
    "link": "https://choiceindia.com/upcoming-bonus-shares"
  },
  "upcoming-dividend-paying-stocks": {
    "title": "Upcoming Dividend in 2024 - List of Upcoming Dividend Paying Stocks in India",
    'lang': "en",
    'content': "Get the list of upcoming dividend paying stocks in 2024 on Choice. Check out the upcoming dividend dates declared by companies in NSE.",
    "link": "https://choiceindia.com/upcoming-dividend-paying-stocks"
  },
  "upcoming-stock-splits": {
    "title": "Upcoming Stock Splits in 2024 - List of Recent Share Splits in India",
    'lang': "en",
    'content': "Find out upcoming stock splits in 2024 on Choice. View the list recent share splits in India by NSE & BSE listed companies along with it's face value.",
    "link": "https://choiceindia.com/upcoming-stock-splits"
  },
  "upcoming-rights-issue": {
    "title": "Upcoming Rights Issue in 2024 - List of Upcoming Rights Issue of Shares",
    'lang': "en",
    'content': "Find the list of upcoming rights issue in 2024 on Choice. Get the complete list of recently announced rights issue of shares in Indian stock market.",
    "link": "https://choiceindia.com/upcoming-rights-issue"
  },
  "campaign/free-demat-account": {
    "title": "Free Demat Account Opening Online with No Annual Charges",
    'lang': "en",
    'content': "Free demat account opening online with no annual charges* (AMC) at Choice. Open demat account with free expert research, low DP & brokerage charges.",
    "link": "https://choiceindia.com/campaign/free-demat-account"
  },
  "campaign/trading-strategies": {
    "title": "Trading Strategies - Best Intraday & Options Trading Strategies",
    'lang': "en",
    'content': "Create your own trading strategies with Strategy by Choice. Stratezy is one of the best intraday & options trading strategy builder for Indian market.",
    "link": "https://choiceindia.com/campaign/trading-strategies"
  },
  "campaign/hindi/trading-strategies": {
    "title": "ट्रेडिंग रणनीतियाँ - सर्वश्रेष्ठ इंट्राडे और ऑप्शंस ट्रेडिंग रणनीतियाँ",
    'lang': "hi",
    'content': "चॉइस द्वारा Stratezy के साथ अपनी खुद की ट्रेडिंग रणनीतियां बनाएं। Stratezy भारतीय बाजार के लिए सर्वश्रेष्ठ इंट्राडे और ऑप्शंस ट्रेडिंग रणनीति बिल्डरों में से एक है।",
    "link": "https://choiceindia.com/campaign/hindi/trading-strategies"
  },
  "campaign/commodity-trading": {
    "title": "Commodity Trading - Don't Miss Commodity Trading Opportunities",
    'lang': "en",
    'content': "Start trading in commodity market with Choice. Open a free demat account with zero AMC for first year, free expert research, low DP & brokerage charges.",
    "link": "https://choiceindia.com/open-free-demat-account"
  },
  "campaign/forex-trading": {
    "title": "Forex Trading - Don't Miss Trading Opportunities in Currency Market",
    'lang': "en",
    'content': "Start trading in forex (currency market) with Choice. Open free demat account with zero AMC for first year, free expert research, low DP & brokerage charges.",
    "link": "https://choiceindia.com/open-free-demat-account"
  },
  "campaign/technical-analysis": {
    "title":"Your Technical Analysis Skills Don’t Pay Off - Explore Stratezy",
    'lang':"en",
    'content':"Don't have time for daily chart analysis? Trade with daily stock recommendation from the expert at Choice. Start creating your own strategy now!",
  },
    "campaign/futures-options-trading": {
    "link":"https://choiceindia.com/open-free-demat-account"
  },
  "campaign/futures-options-trading": {
    "title": "F&O Trading - Don't Miss Futures & Options Trading Opportunities",
    'lang': "en",
    'content': "Start trading in Futures and Options (F&O) with Choice. Open a free demat account with No AMC for first year, free expert research, low DP & brokerage charges.",
    "link": "https://choiceindia.com/open-free-demat-account"
  },
  "bajaj-energy-ipo": {
    "title":"Bajaj Energy IPO - Check Issue Date, Price, Lot Size & Details",
    'lang':"en",
    'content':"Discover the latest details about the Bajaj Energy IPO, including the issue date, price, lot size, and other information. Stay updated and make informed investment decisions.",
    "link":"https://choiceindia.com/bajaj-energy-ipo"
  },
  
  "brokerage-calculator": {
    "title": "Brokerage Calculator - Calculate Intraday & Delivery Brokerage Charges",
    'lang': "en",
    'content': "Use our brokerage calculator to verifyr intraday & delivery brokerage charges. Know your estimate brokerage fees for Futures & Options, Commodity and Currency",
    "link": "https://choiceindia.com/brokerage-calculator"
  },
  "oi-spurts": {
    "title": "OI Spurts - Live Open Interest Chart Data for Futures & Options",
    'lang': "en",
    'content': "Get LIVE OI Spurts on Choice. Find out the LIVE open interest chart data analysis for Futures & Options of NSE/BSE listed stocks & indices.",
    "link": "https://choiceindia.com/oi-spurts"
  },
  "sip-calculator": {
    "title": "SIP Calculator - Calculate Mutual Fund Returns Online at Choice",
    'lang': "en",
    'content': "Calculate the future mutual fund returns online through our SIP Calculator to know the future value of your SIP or lump sum mutual fund investment.",
    "link": "https://choiceindia.com/sip-calculator"
  },
  "mutual-fund-calculator": {
    "title": "Mutual Fund Calculator - Calculate Your SIP & Lumpsum Return",
    'lang': "en",
    'content': "Mutual fund calculator is an online tools which helps you to calculate mutual fund returns. Find out future ROI of your SIP & lumpsum Investment.",
    "link": "https://choiceindia.com/mutual-fund-calculator"
  },
  "margin-calculator": {
    "title": "Margin Calculator - Calculate Margin for Your Trading",
    'lang': "en",
    'content': "Margin Calculator - A free tool which helps stock traders to calculate the margin or leverage they needs for trades.",
    "link": "https://choiceindia.com/margin-calculator"
  },
  "futures-and-options-margin-calculator": {
    "title": "F&O Margin Calculator - Calculate Margin for Option & Future Selling",
    'lang': "en",
    'content': "Choice F&O Margin Calculator is a free tool for f&o traders to calculate margin for futures and options selling.",
    "link": "https://choiceindia.com/futures-and-options-margin-calculator"
  },
  "commodity-margin-calculator": {
    "title": "MCX Margin Calculator - Calculate Your Commodity Trading Margin",
    'lang': "en",
    'content': "Choice MCX margin calculator is an online commodity margin calculator useful for traders in the commodity market.",
    "link": "https://choiceindia.com/commodity-margin-calculator"
  },
  "forex-margin-calculator": {
    "title": "Forex Margin Calculator - Calculate your leverage for Currency Trades",
    'lang': "en",
    'content': "Calculate your leverage with our Forex margin calculator. Make use of the currency margin calculator to make fx trading easier and fast.",
    "link": "https://choiceindia.com/forex-margin-calculator"
  },
  "option-chain": {
    "title": "Option Chain - Live Call & Put Option Chain Data Analysis",
    'lang': "en",
    'content': "Check out option chain LIVE data on Choice. Get Call & Put option chain data with LIVE strike price, analysis, chart, historical data and more.",
    "link": "https://choiceindia.com/option-chain",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "Can I trade options directly from the option chain?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, the option chain primarily serves as a source of information. To trade options, you need a trading account with a broker offering options trading and placing trades through their trading platform."
        }
      },{
        "@type": "Question",
        "name": "Is option chain data available for all underlying assets?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Option chain data is typically available for actively traded stocks, indexes, and some exchange-traded funds (ETFs). Less liquid or thinly traded assets may have limited or no option chain data."
        }
      },{
        "@type": "Question",
        "name": "How often is option chain data updated?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Option chain data is updated in real-time as trades occur in the options market. The frequency of updates depends on the specific data provider or platform you are using."
        }
      },{
        "@type": "Question",
        "name": "What factors should I consider when analyzing the option chain?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When analyzing the option chain, factors include open interest, volume, implied volatility, and strike price selection. These factors provide insights into market sentiment, trading activity, and potential price movements."
        }
      },{
        "@type": "Question",
        "name": "Can option chain analysis predict future market movements?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Option chain analysis is a valuable tool for understanding market sentiment and identifying potential trading opportunities. However, it does not guarantee predicting future market movements, as multiple factors influence price dynamics. Traders should consider it alongside other forms of analysis and risk management strategies."
        }
      }]
    }
    </script> 
    `},

  "nse-option-chain": {
    "title": "NSE Option Chain - Live NSE India Option Chain Data, Chart, Analysis",
    'lang': "en",
    'content': "Find out NSE option chain data on Choice. Get live NSE India option chain data with real-time chart, analysis, historical data and more.",
    "link": "https://choiceindia.com/nse-option-chain",
    "faqscript": `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What are ‘calls’ and ‘puts’ in the NSE option chain?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "In an NSE option chain, calls provide information about the buying performance pertaining to different options contracts, while puts signify the selling information for the same."
        }
      },{
        "@type": "Question",
        "name": "How does the option chain work?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "An option chain is a chart that details all the options contracts in the market and gives an overview of their bidding prices, volatility and an estimate of their future performance. You can use it to decide on purchasing or selling an options contract."
        }
      },{
        "@type": "Question",
        "name": "What is NSE option chain data?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The NSE option chain data consists of information about the essentials of the options contracts available in the market. The data is focused on the performance of each contract to help traders make informed decisions."
        }
      }]
    }
    
    </script> 
    `},
  "mcx-option-chain": {
    "title": "MCX Option Chain - Check Commodity Option Chain Data Live",
    'lang': "en",
    'content': "Get live MCX option chain data on Choice. Check out real-time commodity option chain with strike price, analysis, chart, historical data and more.",
    "link": "https://choiceindia.com/mcx-option-chain"
  },
  "currency-option-chain": {
    "title": "Currency Option Chain - Check Live Forex Option Chain Data",
    'lang': "en",
    'content': "Check out LIVE currency option chain on Choice. Get forex option chain data with real time strike price, analysis, chart, historical data and more.",
    "link": "https://choiceindia.com/currency-option-chain"
  },
  "campaign/fno-trading": {
    "title": "F&O Trading - Don't Miss Futures & Options Trading Opportunities",
    'lang': "en",
    'content': "Start trading in Futures and Options (F&O) with Choice. Open a free demat account with No AMC for first year, free expert research, low DP & brokerage charges.",
    "link": "https://choiceindia.com/campaign/fno-trading"
  },
  "campaign/intraday-trading": {
    "title": "Intraday Trading - Maximize Your Day Trading Profits with Choice",
    'lang': "en",
    'content': "Start intraday trading with Choice & maximize your intraday profits with advanced tools and personalized services. Open your free demat account now!",
    "link": "https://choiceindia.com/campaign/fno-trading"
  },
  "mutual-fund-app": {
    "title": "Choice FinX Mutual Fund App - India's Best App for Mutual Fund Investment",
    'lang': "en",
    'content': "Choice FinX Mutual Fund App is the best mutual fund app in India to invest, manage & track your mutual fund investments. Download our top-rated mutual fund app (Android & iOS) now!",
    "link": "https://choiceindia.com/mutual-fund-app"
  }, 
  "corporate-demat-account": {
    "title": "Open a Corporate Demat Account Online with Choice",
    'lang': "en",
    'content': "Open a Corporate Demat account with Choice now! Manage business investments by exploring corporate demat account, its benefits, types, opening process, documents needed & more",
    "link": "https://choiceindia.com/corporate-demat-account"
  },
  "nach-cancellation": {
    "title": "Online NACH Mandate Cancellation",
    "lang": "en",
    "content": "Request online nach cancellation with Choice.Fill the details to file a request for NACH mandate cancellation.",
    "link":"https://choiceindia.com/nach-cancellation"
  },
  "merchant-investment-banking": {
    "title": " Merchant Banking and Investment Banking",
    "lang": "en",
    "content": "Elevate your financial strategy with experienced Merchant and Investment Banking services with the category 1 merchant banker, Choice.",
    "link":"https://choiceindia.com/merchant-investment-banking"
  },
   "track-record-page": {
  "title":"Track Record-Choice India",
  "lang": "en",
  "content":"We believe transparency and success should go hand in hand. Check out Choice's impeccable track record for yourself",
  "link":"https://choiceindia.com/track-record-page"
  },
  "code-of-conduct": {
    "title": "Code of Conduct - Choice India",
    "lang": "en",
    "content": "Go through our code of conduct page for comprehensive guidelines on ethical behavior, fostering a respectful and inclusive environment for all stakeholders.",
    "link":"https://choiceindia.com/code-of-conduct"
  },
  "related-party-transaction": {
   "title": "Related Party Transaction - Choice India",
    "lang": "en",
    "content": "Go through the disclosure of the related partys and their respective details.",
    "link":"https://choiceindia.com/related-party-transaction"
  },
  "investor-presentation": {
   "title": "Investor Presentation - Choice India",
    "lang": "en",
    "content": "Explore through the investor presentation and get to know about the company details all over.",
    "link":"https://choiceindia.com/investor-presentation"
  },
  "closure-of-trading-window": {
   "title": "Closure of Trading Window - Choice India",
    "lang": "en",
    "content": "Official letters reguarding the closure of trading window for the respective months and duration.",
    "link":"https://choiceindia.com/closure-of-trading-window"
  },
  "bug-bounty-program": {
    "title": "Choice Bug Bounty",
    'lang': "en",
    'content': "Contribute for making Choice the most trusted investment platform.",
    "link": "https://choiceindia.com/bug-bounty-program"
  },
  "government-advisory": {
    "title": "Government advisory - Choice India",
    "lang":"en",
    "content": "Check out Choice India's government advisory services that provide end to end project management solutions to the government.",
    "link":"https://choiceindia.com/government-advisory"
  },
  "what-is-trading-account": {
    "title": "Trading Account - Meaning, Definition, Features, Benefits, Types",
    "lang":"en",
    "content": "A Trading account is a must for trading in the securities market. Know what is trading account, it’s purpose, types, benefits, charges, how it works and ways to earn with trading account.",
    "link":"https://choiceindia.com/what-is-trading-account"
  }

}

export default meta_tags;
